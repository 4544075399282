import React from "react";
import { Link } from "react-router-dom";
import imgUrl from "../../../assets/all-images/assetmobil/alphard_innova_avanza.webp";

const CardCar = (params) => {
  console.log(params);
  return (
    <div className="mb-5">
      <div className="car__item">
        <div className="car__img text-center">
          <img src={imgUrl} alt="" className="w-75 mx-auto" />
        </div>
        <div className="car__item-content mt-4">
          <h4 className="section__title ">{params.data.title}</h4>
          <h6 className="rent__price text-center mt-">{params.data.harga}</h6>
          <button className="w-1/2 car__item-btn car__btn-rent">
            <Link to={`/cars/`}>Sewa</Link>
          </button>
          <button className="w-1/2 car__item-btn car__btn-details">
            <Link to={`/cars/`}>Detail</Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardCar;
