import semarang from "../wisata/semarang.jpeg";
import bali from "../wisata/bali.jpeg";
import bandung from "../wisata/bandung.jpeg";
import jakarta from "../wisata/jakarta.jpeg";
import jogjakarta from "../wisata/jogjakarta.jpeg";
import surabaya from "../wisata/surabaya.jpeg";
import tangerang from "../wisata/tangerang.jpeg";
import malang from "../wisata/malang.jpeg";
import bogor from "../wisata/bogor.jpeg";

const dataCardWisata = [
  {
    title: "Semarang",
    image: semarang,
  },
  {
    title: "Bali",
    image: bali,
  },
  {
    title: "Bandung",
    image: bandung,
  },
  {
    title: "Jakarta",
    image: jakarta,
  },
  {
    title: "Surabaya",
    image: surabaya,
  },
  {
    title: "Jogjakarta",
    image: jogjakarta,
  },
  {
    title: "Tangerang",
    image: tangerang,
  },
  {
    title: "Malang",
    image: malang,
  },
  {
    title: "Bogor",
    image: bogor,
  },
];

export default dataCardWisata;
