import React from "react";
import { Col, Container } from "reactstrap";

const CardWisata = (props) => {
  return (
    <div className="max-w-xs rounded overflow-hidden shadow-lg">
      <img className="w-full" src={props.gambar} alt={props.title} />
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{props.title}</div>
        <p className="text-gray-700 text-base">Dapatkan Harga Spesial</p>
      </div>
      <div className="px-6 py-4 flex flex-wrap gap-2 justify-center">
        <button className="bg-title hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">
          WhatsApp
        </button>
        <button className="bg-title hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2">
          Call
        </button>
        <button className="bg-title hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
          Details
        </button>
      </div>
    </div>
  );
};

export default CardWisata;
