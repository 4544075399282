import React, { useEffect, useState } from "react";
import { Table, Button, Space, message } from "antd";
import { Col, Container } from "reactstrap";
import { deleteBlog, getBlogList } from "../../../utils/api";
import { isoToDate } from "../../../utils/global_method";

const ListBlog = () => {
  const columns = [
    {
      title: "Judul",
      dataIndex: "judul",
      key: "judul",
    },
    {
      title: "Tanggal Publish",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Konten",
      dataIndex: "konten",
      key: "konten",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
    },
    {
      title: "Action",
      dataIndex: "key",
      key: "x",
      render: (key, data) => (
        <Space wrap key={"test"}>
          <Button type="primary">Edit</Button>
          <Button
            type="danger"
            onClick={() => {
              hapusData(data.id);
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const hapusData = (id) => {
    deleteBlog(id)
      .then((result) => {
        console.log("result", result);

        setBlog((pre) => {
          return pre.filter((data) => data.id !== id);
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  //initstate
  const [dataBlog, setBlog] = useState([]);
  useEffect(() => {
    getBlogList().then((result) => {
      const dataPenampung = [];
      result.data.forEach((element) => {
        dataPenampung.push({
          id: element.id,
          judul: element.judul,
          createdAt: isoToDate(element.createdAt),
          // konten: <p dangerouslySetInnerHTML={{ __html: element.konten }} />,
          konten: element.slug,
          image: <img alt="" src={element.image} style={{ width: 100 }}></img>,
        });
        setBlog(dataPenampung);
      });
    });
  }, [dataBlog]);

  return (
    <Container className="w-75 mt-3">
      <Col>
        <Table
          columns={columns}
          expandable={{
            expandedRowRender: (record, index) => (
              <p
                key={index}
                style={{
                  margin: 0,
                }}
              >
                {record.konten}
              </p>
            ),
          }}
          dataSource={dataBlog}
        />
      </Col>
    </Container>
  );
};

export default ListBlog;
