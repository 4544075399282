import axios from "axios";

const apiURL = "http://localhost:8000/";
const blog = "blogs";
const testimoni = "testimoni";

export const getBlogList = async () => {
  const blogList = await axios.get(`${apiURL}${blog}`);
  return blogList.data;
};

export const postBlog = async () => {
  const postBlog = await axios.post(`${apiURL}${blog}`);
  return postBlog;
};

export const deleteBlog = async (id) => {
  // console.log(`${apiURL}/${blog}/${id}`);
  const deleteBlog = await axios.delete(`${apiURL}${blog}/${id}`);
  return deleteBlog;
  // console.log("ini id dari utils", id);
};

export const getTestimoni = async () => {
  // console.log(`${apiURL}/${urlBlog}/${id}`);
  const getTestimoni = await axios.get(`${apiURL}${testimoni}`);
  return getTestimoni.data;
  // console.log("ini id dari utils", id);
};
