import React from "react";
import { Col } from "reactstrap";
import "../../styles/blog-item.css";
import { Link } from "react-router-dom";
import "../../index.css";
import { dateToHour, isoToDate } from "../../utils/global_method";

const BlogList = (props) => {
  // console.log("inipropsdata", props.data);
  return (
    <div className="flex flex-wrap lg:grid lg:grid-cols-3 gap-3 mx-auto justify-center">
      {props.data.map((item) => (
        <BlogItem item={item} key={item.id}></BlogItem>
      ))}
    </div>
  );
};

const BlogItem = ({ item }) => {
  console.log("ini item dari blog item", item.image);
  return (
    <div className="mb-5 w-[300px] mx-auto">
      <div className="blog__item">
        <img src={item.image} alt="" className="w-[300px] inline-block" />
        <div className="blog__info p-3">
          <Link to={`/blogs/${item.judul}`} className="blog__title">
            {item.judul}
          </Link>
          <div className="section__description mt-3 truncate-50">
            <p>{item.slug}...</p>
          </div>

          <Link to={`/blogs/${item.judul}`} className="read__more">
            Read More
          </Link>

          <div className="blog__time pt-3 mt-3 d-flex align-items-center justify-content-between">
            <span className="blog__author">
              <i class="ri-user-line"></i>
              {item.pembuat}
            </span>
            <div className="d-flex align-items-center gap-3">
              <span className="d-flex align-items-center gap-1 section__description">
                <i class="ri-calendar-line"></i>
                {isoToDate(item.createdAt)}
              </span>
              <span className="d-flex align-items-center gap-1 section__description">
                <i class="ri-time-line"></i>
                {dateToHour(item.createdAt)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlogList;
