import React from "react";
import { Link } from "react-router-dom";

const CardList = (props) => {
  return (
    <div className="max-w-md w-1/4 rounded overflow-hidden shadow-lg bg-white border p-4">
      <h6 className="ml-5 mt-1 text-lg font-semibold">Rental {props.title}</h6>
      <ul>
        <Link to="/" className="text-black no-underline ">
          <li className="flex items-center hover:text-subtitle mb-1">
            <span className="h-[8px] w-[8px] rounded-full bg-black inline-block mr-[10px]"></span>
            Rental {props.title} Jakarta
          </li>
        </Link>

        <Link to="/" className="text-black no-underline ">
          <li className="flex items-center hover:text-subtitle mb-1">
            <span className="h-[8px] w-[8px] rounded-full bg-black inline-block mr-[10px]"></span>
            Rental {props.title} Depok
          </li>
        </Link>
        <Link to="/" className="text-black no-underline ">
          <li className="flex items-center hover:text-subtitle mb-1">
            <span className="h-[8px] w-[8px] rounded-full bg-black inline-block mr-[10px]"></span>
            Rental {props.title} Bekasi
          </li>
        </Link>
        <Link to="/" className="text-black no-underline ">
          <li className="flex items-center hover:text-subtitle mb-1">
            <span className="h-[8px] w-[8px] rounded-full bg-black inline-block mr-[10px]"></span>
            Rental {props.title} Tangerang
          </li>
        </Link>
        <Link to="/" className="text-black no-underline ">
          <li className="flex items-center hover:text-subtitle mb-1">
            <span className="h-[8px] w-[8px] rounded-full bg-black inline-block mr-[10px]"></span>
            Rental {props.title} Bogor
          </li>
        </Link>
      </ul>
    </div>
  );
};

export default CardList;
