import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import CarListing from "../pages/CarListing";
import CarDetails from "../pages/CarDetails";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import NotFound from "../pages/NotFound";
import Login from "../components/UI/Login";
import Dashboard from "../components/UI/Dashboard";
import Travel from "../components/UI/Travel/Travel";
import TravelSlug from "../components/UI/Travel/TravelSlug";
import Wisata from "../components/UI/Wisata/Wisata";
import WisataSlug from "../components/UI/Wisata/WisataSlug";
import RentalMobilSlug from "../components/UI/Rental-Mobil/RentalMobilSlug";
import RentalMobil from "../components/UI/Rental-Mobil/RentalMobil";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/login" element={<Login />} />

      <Route path="/home" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/cars" element={<CarListing />} />
      <Route path="/cars/:slug" element={<CarDetails />} />
      <Route path="/blogs" element={<Blog />} />
      <Route path="/blogs/:slug" element={<BlogDetails />} />
      <Route path="/travel" element={<Travel />} />
      <Route path="/travel/:slug" element={<TravelSlug />} />
      <Route path="/wisata" element={<Wisata />} />
      <Route path="/wisata/:slug" element={<WisataSlug />} />
      <Route path="/rental-mobil" element={<RentalMobil />} />
      <Route path="/rental-mobil/:slug" element={<RentalMobilSlug />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routers;
