import Routers from "../../routes/Routers";

const Layout = () => {
  return (
    <div>
      <Routers />
    </div>
  );
};

export default Layout;
