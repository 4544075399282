import { Carousel, Image } from "antd";
import React, { Fragment } from "react";
import { Col, Container } from "reactstrap";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import Helmet from "../../Helmet/Helmet";
import gambar4 from "../../../assets/carousel/1.jpeg";
import gambar1 from "../../../assets/carousel/2.jpeg";
import gambar3 from "../../../assets/carousel/3.jpeg";
import gambar2 from "../../../assets/carousel/4.jpeg";
import CardWisata from "./CardWisata";
import dataCardWisata from "../../../assets/data/dataCardWisata";

const Wisata = () => {
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  const contentStyle = {
    margin: 0,
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };
  return (
    <Fragment>
      <Header />
      <Helmet title="Wisata">
        <section>
          <Container>
            <Col className="text-center">
              <h2 className="section__title">Paket Wisata</h2>
              <Carousel afterChange={onChange}>
                <div>
                  <Image src={gambar1} style={contentStyle} />
                </div>
                <div>
                  <Image src={gambar2} style={contentStyle} />
                </div>
                <div>
                  <Image src={gambar3} style={contentStyle} />
                </div>
                <div>
                  <Image src={gambar4} style={contentStyle} />
                </div>
              </Carousel>
            </Col>
          </Container>
        </section>
        <section>
          <Container>
            <Col className="text-center">
              <h6 className="section__subtitle">Wisata</h6>
              <h2 className="section__title">
                Paket Wisata Murah Terlengkap Lainnya
              </h2>
              <div className="grid grid-cols-3 gap-4 mt-4">
                {dataCardWisata.map((data) => (
                  <CardWisata title={data.title} gambar={data.image} />
                ))}
              </div>
            </Col>
          </Container>
        </section>
      </Helmet>
      <Footer />
    </Fragment>
  );
};

export default Wisata;
