const kecamatan = {
  jaktim: [
    "Cakung Barat",
    "Cakung Timur",
    "Jatinegara",
    "Penggilingan",
    "Pulo Gebang",
    "Rawa Terate",
    "Ujung Menteng",
    "Bambu Apus",
    "Ceger",
    "Cilangkap",
    "Cipayung",
    "Lubang Buaya",
    "Munjul",
    "Pondok Ranggon",
    "Setu",
    "Cibubur",
    "Ciracas",
    "Kelapa Dua Wetan",
    "Rambutan",
    "Susukan",
    "Duren Sawit",
    "Klender",
    "Malaka Jaya",
    "Malaka Sari",
    "Pondok Bambu",
    "Pondok Kelapa",
    "Podok Kopi",
    "Bali Mester",
    "Bidara Cina",
    "Cipinang Besar Selatan",
    "Cipinang Besar Utara",
    "Cipinang Cempedak",
    "Cipinang Muara",
    "Kampung Melayu",
    "Rawa Bunga",
    "Balekambang",
    "Batu Ampar",
    "Cawang",
    "Cilitan",
    "Dukuh",
    "Kramat Jati",
    "Tengah",
    "Cipinang Melayu",
    "Halim Perdana Kusuma",
    "Kebon Pala",
    "Makasar",
    "Pinang Ranti",
    "Kayu Manis",
    "Kebon Manggis",
    "Palmeriam",
    "Pisangan Baru",
    "Utan Kayu Selatan",
    "Utan Kayu Utara",
    "Baru",
    "Cijantung",
    "Gedong",
    "Kalisari",
    "Pekayon",
    "Cipinang",
    "Jati",
    "Jatinegara Kaum",
    "Kayu Putih",
    "Pisangan Timur",
    "Pulo Gadung",
    "Rawa Mangun",
  ],
  jakbar: [
    "Slipi",
    "Glodok",
    "Keagungan",
    "Krukut",
    "Mangga Besar",
    "Maphar",
    "Pinangsia",
    "Taman Sari",
    "Tangki",
    "Angke",
    "Duri Selatan",
    "Duri Utara",
    "Jembatan Besi",
    "Jembatan Lima",
    "Kali Anyar",
    "Krendang",
    "Pekojan",
    "Roa Malaka",
    "Tambora",
    "Tanah Sereal",
    "Duri Kepa",
    "Kebon Jeruk",
    "Kedoya Selatan",
    "Kedoya Utara",
    "Kelapa Dua",
    "Sukabumi Selatan",
    "Sukabumi Utara",
    "Joglo",
    "Kembangan Selatan",
    "Kembangan Utara",
    "Meruya Selatan",
    "Meruya Utara",
    "Srengseng",
    "Jatipulo",
    "Kemanggisan",
    "Kota Bambu Selatan",
    "Kota Bambu Utara",
    "Palmerah",
    "Cengkareng Barat",
    "Cengkareng Timur",
    "Duri Kosambi",
    "Cengkareng Barat",
    "Kedaung Kali Angke",
    "Rawa Buaya",
    "Grogol",
    "Jelambar",
    "Jelambar Baru",
    "Tanjung Duren Selatan",
    "Tanjung Duren Utara",
    "Tomang",
    "Wijaya Kusuma",
    "Kalideres",
    "Kamal",
    "Pegadungan",
    "Semanan",
    "Tegal Alur",
  ],
  jakpus: [
    "Cempaka Putih",
    "Cempaka Putih Barat",
    "Cempaka Putih Timur",
    "Rawasari",
    "Cideng",
    "Duri Pulo",
    "Gambir",
    "Kebon Kelapa",
    "Petojo Selatan",
    "Petojo Utara",
    "Galur",
    "Johar Baru",
    "Kampung Rawa",
    "Tanah Tinggi",
    "Cempaka Baru",
    "Gunung Sahari Selatan",
    "Harapan Mulia",
    "Kebon Kosong",
    "Kemayoran",
    "Serdang",
    "Sumur Batu",
    "Utan Panjang",
    "Cikini",
    "Gondangdia",
    "Kebon Sirih",
    "Menteng",
    "Pegangsaan",
    "Gunung Sahari Utara",
    "Karang Anyar",
    "Kartini",
    "Mangga Dua Selatan",
    "Pasar Baru",
    "Bungur",
    "Kenari",
    "Keramat",
    "Kwitang",
    "Senen",
    "Bendungan Hilir",
    "Gelora",
    "Kampung Bali",
    "Karet Tengsin",
    "Kebon Kacang",
    "Kebon Melati",
    "Petamburan",
  ],
  jakut: [
    "Cilincing",
    "Kalibaru",
    "Marunda",
    "Rorotan",
    "Semper Barat",
    "Semper Timur",
    "Sukapura",
    "Kelapa Gading Barat",
    "Kelapa Gading Timur",
    "Pegangsaan Dua",
    "Koja",
    "Lagoa",
    "Rawa Badak Selatan",
    "Rawa Badak Utara",
    "Tugu Selatan",
    "Tugu Utara",
    "Ancol",
    "Pademangan Barat",
    "Pademangan Timur",
    "Kamal Muara",
    "Kapuk Muara",
    "Pejagalan",
    "Penjaringan",
    "Pluit",
    "Kebon Bawang",
    "Papanggo",
    "Sungai Bambu",
    "Sunter Agung",
    "Sunter Jaya",
    "Tanjung Priok",
    "Warakas",
  ],
  jaksel: [
    "Gandaria Utara",
    "Cipete Utara",
    "Pulo",
    "Petogogan",
    "Melawai",
    "Kramat Pela",
    "Gunung",
    "Selong",
    "Rawa Barat",
    "Senayan",
    "Lebak Bulus",
    "Pondok Labu",
    "Cilandak Barat",
    "Gandaria Selatan",
    "Cipete Selatan",
    "Kuningan Barat",
    "Pela Mampang",
    "Tegal Parang",
    "Bangka",
    "Mampang Prapatan",
    "Petukangan Selatan",
    "Ulujami",
    "Pesanggrahan",
    "Bintaro",
    "Petukangan Utara",
    "Menteng Dalam",
    "Tebet Barat",
    "Tebet Timur",
    "Kebon Baru",
    "Bukit Duri",
    "Manggarai",
    "Manggarai Selatan",
    "Pondok Pinang",
    "Kebayoran Lama Utara",
    "Cipulir",
    "Grogol Utara",
    "Grogol Selatan",
    "Lama Selatan",
    "Pejaten Barat",
    "Pasar Minggu",
    "Jati Padang",
    "Ragunan",
    "Cilandak",
    "Pejaten Timur",
    "Kebagusan",
    "Srengseng Sawah",
    "Jagakarsa",
    "Lenteng Agung",
    "Cipedak",
    "Tanjung Barat",
    "Ciganjur",
    "Pancoran",
    "Duren Tiga",
    "Rawajati",
    "Pengadegan",
    "Kalibata",
    "Cikoko",
    "Setiabudi",
    "Guntur",
    "Karet",
    "Karet Semanggi",
    "Karet Kuningan",
    "Kuningan Timur",
    "Menteng Atas",
    "Pasar Manggis",
  ],
};
export default kecamatan;
