import React from "react";
import { Col, Container, Row } from "reactstrap";
import aboutImg from "../../assets/all-images/assetmobil/reborn.png";

const Starter = () => {
  return (
    <section className="">
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content">
              <h4 className="section__subtitle">Blog Kami</h4>
              <h2 className="section__title">
                Temukan Informasi Terkini Tentang Rental Mobil di Kota Kami
              </h2>
              <p className="section__description">
                Selamat datang di blog kami! Di sini, Anda bisa mendapatkan
                informasi terkini seputar rental mobil. Mulai dari tips memilih
                mobil yang tepat hingga destinasi wisata yang cocok untuk
                ditempuh dengan mobil, semuanya ada di sini. Selamat membaca!
              </p>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i>Mobil bersih
                </p>
              </div>
              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i>
                  {"Penjemputan < 20 menit"}
                </p>
              </div>
              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i>Kendaraan yang di
                  lengkapi GPS Tracker
                </p>
              </div>
            </div>
          </Col>
          <Col lg="6" md="6">
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Starter;
