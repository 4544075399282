import React, { useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import "../../styles/header.css";
// import logo2 from "../../assets/all-images/logo2.png";
import logo from "../../assets/alclogo.png";
import { useState } from "react";
import useItems from "antd/es/menu/hooks/useItems";
import { Popover } from "antd";

const navLinks = [
  {
    path: "/home",
    display: "Home",
  },
  {
    path: "/about",
    display: "About",
  },
  {
    path: "/cars",
    display: "Cars",
  },
  {
    path: "/blogs",
    display: "Blog",
  },
  {
    path: "/contact",
    display: "Contact",
  },
];

const Header = () => {
  const menuRef = useRef(null);
  const toggleMenu = () => menuRef.current.classList.toggle("menu__active");
  function togglePagesMenu(param) {
    switch (param) {
      case "travel":
        setIsPagesTravelOpen(!isPagesTravelOpen);
        break;

      case "wisata":
        setIsPagesWisataOpen(!isPagesWisataOpen);
        break;

      case "mobil":
        setIsPagesMobilOpen(!isPagesMobilOpen);
        break;

      case "bus":
        setIsPagesBusOpen(!isPagesBusOpen);
        break;

      case "elf":
        setIsPagesElfOpen(!isPagesElfOpen);
        break;

      case "hiace":
        setIsPagesHiaceOpen(!isPagesHiaceOpen);
        break;

      default:
        break;
    }
  }
  const contentTravel = (
    <div className="">
      <a
        className="block text-black no-underline mb-1"
        href="/travel/jakarta-jabodetabek"
      >
        Jakarta - Jabodetabek
      </a>
      <a
        className="block text-black no-underline mb-1"
        href="/travel/jakarta-sumatera"
      >
        Jakarta - Sumatera
      </a>
      <a
        className="block text-black no-underline mb-1"
        href="/travel/jakarta-jawa-tengah"
      >
        Jakarta - Jawa Tengah
      </a>
      <a
        className="block text-black no-underline mb-1"
        href="/travel/jakarta-jawa-timur"
      >
        Jakarta - Jawa Timur
      </a>
      <a
        className="block text-black no-underline mb-1"
        href="/travel/jakarta-jawa-barat"
      >
        Jakarta - Jawa Barat
      </a>
      <a
        className="block text-black no-underline mb-1"
        href="/travel/jakarta-jogjakarta"
      >
        Jakarta - DIY
      </a>
    </div>
  );
  const contentWisata = (
    <div className="">
      <a
        href="/wisata/semarang"
        class="block text-black no-underline mb-1 transition-colors duration-500 hover:text-subtitle"
      >
        Semarang
      </a>

      <a
        className="block text-black no-underline mb-1 hover:text-subtitle"
        href="/wisata/bali"
      >
        Bali
      </a>
      <a
        className="block text-black no-underline mb-1 hover:text-subtitle"
        href="/wisata/bandung"
      >
        Bandung
      </a>
      <a
        className="block text-black no-underline mb-1 hover:text-subtitle"
        href="/wisata/jakarta"
      >
        Jakarta
      </a>
      <a
        className="block text-black no-underline mb-1 hover:text-subtitle"
        href="/wisata/surabaya"
      >
        Surabaya
      </a>
      <a
        className="block text-black no-underline mb-1 hover:text-subtitle"
        href="/wisata/jogjakarta"
      >
        Jogjakarta
      </a>
      <a
        className="block text-black no-underline mb-1 hover:text-subtitle"
        href="/wisata/tangerang"
      >
        Tangerang
      </a>
      <a
        className="block text-black no-underline mb-1 hover:text-subtitle"
        href="/wisata/malang"
      >
        Malang
      </a>
      <a
        className="block text-black no-underline mb-1 hover:text-subtitle"
        href="/wisata/bogor"
      >
        Bogor
      </a>
    </div>
  );
  const [isPagesTravelOpen, setIsPagesTravelOpen] = useState(false);
  const [isPagesWisataOpen, setIsPagesWisataOpen] = useState(false);
  const [isPagesMobilOpen, setIsPagesMobilOpen] = useState(false);
  const [isPagesBusOpen, setIsPagesBusOpen] = useState(false);
  const [isPagesElfOpen, setIsPagesElfOpen] = useState(false);
  const [isPagesHiaceOpen, setIsPagesHiaceOpen] = useState(false);
  return (
    <header className="header">
      {/* ------------ header top ------------ */}
      <div className="header__top">
        <Container color="red">
          <Row>
            <Col lg="6" md="6" sm="6">
              <div className="header__top__left">
                <span>Butuh Bantuan?</span>
                <span className="header__top__help">
                  <i class="ri-phone-fill"></i> +62 851-5602-4563
                </span>
              </div>
            </Col>
            <Col lg="6" md="6" sm="6">
              <div className="header__top__right d-flex align-items-center justify-content-end gap-3">
                <Link
                  to="/dashboard"
                  className="d-flex align-items-center gap-1"
                >
                  <i class="ri-login-circle-line"></i> Login
                </Link>

                <Link to="#" className="d-flex align-items-center gap-1">
                  <i class="ri-user-line"></i> Register
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* =============== header middle =========== */}
      <div className="header__middle align-items-start">
        <Container>
          <Row>
            <Col lg="4" md="3" sm="4">
              <div className="logo header__logo">
                <h1>
                  <Link to="/home" className="d-flex align-items-center gap-3">
                    <img src={logo} alt="" className="w-25" />
                    <span>
                      Aksara Lintas Cakrawala <br /> Rent Car{" "}
                    </span>
                  </Link>
                </h1>
              </div>
            </Col>
            <Col lg="3" md="3" sm="4" className="d-flex align-items-center">
              <div className="header__location d-flex align-items-center gap-2">
                <span>
                  <i class="ri-earth-line"></i>
                </span>
                <div className="header__location-content">
                  <h4>Indonesia</h4>
                  <h6>JABODETABEK</h6>
                </div>
              </div>
            </Col>

            <Col lg="3" md="3" sm="4" className="d-flex align-items-center">
              <div className="header__location d-flex align-items-center gap-2">
                <span>
                  <i class="ri-time-line"></i>
                </span>
                <div className="header__location-content">
                  <h4>Setiap hari</h4>
                  <h6>24 Jam</h6>
                </div>
              </div>
            </Col>

            <Col
              lg="2"
              md="3"
              sm="0"
              className="d-flex align-items-center justify-content-end"
            >
              <button className="header__btn btn">
                <Link to="/contact">
                  <i class="ri-phone-line"></i> Hubungi Kami
                </Link>
              </button>
            </Col>
          </Row>
        </Container>
      </div>

      {/* ========== main navigation =========== */}
      <div className="main__navbar">
        <Container>
          <div className="navigation__wrapper d-flex align-items-center justify-content-between">
            <span className="mobile__menu">
              <i class="ri-menu-line" onClick={toggleMenu}></i>
            </span>

            <div
              className="navigation"
              ref={menuRef}
              // onClick={toggleMenu}
            >
              <div className="menuheader">
                <NavLink
                  to="/Home"
                  className={(navClass) =>
                    navClass.isActive
                      ? "nav__active nav__item  cursor-pointer"
                      : "nav__item  cursor-pointer"
                  }
                >
                  Home
                </NavLink>
                <div className={`${isPagesTravelOpen ? "" : "flex"}`}>
                  <div className="flex mx-auto justify-center">
                    {window.innerWidth <= 640 ? (
                      <div
                        onClick={() => {
                          if (window.innerWidth <= 640) {
                            // handle click behavior for mobile screens
                            togglePagesMenu("travel");
                          } else {
                            // handle click behavior for desktop screens
                            // toggle menu without updating state
                            window.location.href = "/travel";
                          }
                        }}
                        className="nav__item menu__utama  cursor-pointer"
                      >
                        Travel
                      </div>
                    ) : (
                      <Popover
                        content={contentTravel}
                        title="Travel"
                        trigger="hover"
                      >
                        <div
                          onClick={() => {
                            if (window.innerWidth <= 640) {
                              // handle click behavior for mobile screens
                              togglePagesMenu("travel");
                            } else {
                              // handle click behavior for desktop screens
                              // toggle menu without updating state
                              window.location.href = "/travel";
                            }
                          }}
                          className="nav__item menu__utama  cursor-pointer"
                        >
                          Travel
                        </div>
                      </Popover>
                    )}

                    <svg
                      className={`w-4 h-4 ${
                        isPagesTravelOpen ? "svg-dropdown" : "svg-updown"
                      }`}
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  {isPagesTravelOpen && (
                    <div className="flex flex-col justify-center mx-auto text-xs mt-3 sm:hidden">
                      <p>Jakarta - Jabodetabek</p>
                      <p>Jakarta - Sumatera</p>
                      <p>Jakarta - Jawa Tengah</p>
                      <p>Jakarta - Jawa Timur</p>
                      <p>Jakarta - Jawa Barat</p>
                      <p>Jakarta - DIY</p>
                    </div>
                  )}
                </div>
                <div className={`${isPagesWisataOpen ? "" : "flex"}`}>
                  <div className="flex mx-auto justify-center">
                    {window.innerWidth <= 640 ? (
                      <div
                        onClick={() => {
                          if (window.innerWidth <= 640) {
                            // handle click behavior for mobile screens
                            togglePagesMenu("wisata");
                          } else {
                            // handle click behavior for desktop screens
                            // toggle menu without updating state
                            window.location.href = "/wisata";
                          }
                        }}
                        className="nav__item menu__utama  cursor-pointer"
                      >
                        Paket Wisata
                      </div>
                    ) : (
                      <Popover
                        content={contentWisata}
                        title="Paket Wisata"
                        trigger="hover"
                      >
                        <div
                          onClick={() => {
                            if (window.innerWidth <= 640) {
                              // handle click behavior for mobile screens
                              togglePagesMenu("wisata");
                            } else {
                              // handle click behavior for desktop screens
                              // toggle menu without updating state
                              window.location.href = "/wisata";
                            }
                          }}
                          className="nav__item menu__utama  cursor-pointer"
                        >
                          Paket Wisata
                        </div>
                      </Popover>
                    )}

                    <svg
                      className={`w-4 h-4 ${
                        isPagesWisataOpen ? "svg-dropdown" : "svg-updown"
                      }`}
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  {isPagesWisataOpen && (
                    <div className="flex flex-col justify-center mx-auto text-xs mt-3 sm:hidden">
                      <p>Jakarta</p>
                      <p>Malang</p>
                      <p>Surabaya</p>
                      <p>Semarang</p>
                      <p>Banten</p>
                      <p>Bogor</p>
                      <p>Bandung</p>
                      <p>Bali</p>
                      <p>Jogjakarta</p>
                    </div>
                  )}
                </div>
                <div className={`${isPagesMobilOpen ? "" : "flex"}`}>
                  <div className="flex mx-auto justify-center">
                    <div
                      onClick={() => {
                        if (window.innerWidth <= 640) {
                          // handle click behavior for mobile screens
                          togglePagesMenu("mobil");
                        } else {
                          // handle click behavior for desktop screens
                          // toggle menu without updating state
                          window.location.href = "/rental-mobil";
                        }
                      }}
                      className="nav__item menu__utama  cursor-pointer"
                    >
                      Rental Mobil
                    </div>
                    <svg
                      className={`w-4 h-4 ${
                        isPagesMobilOpen ? "svg-dropdown" : "svg-updown"
                      }`}
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  {isPagesMobilOpen && (
                    <div className="flex flex-col justify-center mx-auto text-xs mt-3 sm:hidden">
                      <p>Jakarta Pusat</p>
                      <p>Jakarta Selatan</p>
                      <p>Jakarta Utara</p>
                      <p>Jakarta Timur</p>
                      <p>Jakarta Barat</p>
                      <p>Soekarno Hatta</p>
                      <p>Stasiun Gamhir</p>
                      <p>Stasiun Senen</p>
                      <p>Bogor</p>
                      <p>Depok</p>
                      <p>Tangerang</p>
                      <p>Bekasi</p>
                      <p>Semarang</p>
                      <p>Bandung</p>
                      <p>Surabaya</p>
                    </div>
                  )}
                </div>
                <div className={`${isPagesBusOpen ? "" : "flex"}`}>
                  <div className="flex mx-auto justify-center">
                    <div
                      onClick={() => {
                        if (window.innerWidth <= 640) {
                          // handle click behavior for mobile screens
                          togglePagesMenu("bus");
                        } else {
                          // handle click behavior for desktop screens
                          // toggle menu without updating state
                          window.location.href = "/rental-mobil/bus";
                        }
                      }}
                      className="nav__item menu__utama  cursor-pointer"
                    >
                      Rental Bus
                    </div>
                    <svg
                      className={`w-4 h-4 ${
                        isPagesBusOpen ? "svg-dropdown" : "svg-updown"
                      }`}
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  {isPagesBusOpen && (
                    <div className="flex flex-col justify-center mx-auto text-xs mt-3 sm:hidden">
                      <p>Jakarta</p>
                      <p>Bogor</p>
                      <p>Depok</p>
                      <p>Tangerang</p>
                      <p>Bekasi</p>
                    </div>
                  )}
                </div>
                <div className={`${isPagesElfOpen ? "" : "flex"}`}>
                  <div className="flex mx-auto justify-center">
                    <div
                      onClick={() => {
                        if (window.innerWidth <= 640) {
                          // handle click behavior for mobile screens
                          togglePagesMenu("elf");
                        } else {
                          // handle click behavior for desktop screens
                          // toggle menu without updating state
                          window.location.href = "/rental-mobil/elf";
                        }
                      }}
                      className="nav__item menu__utama  cursor-pointer"
                    >
                      Rental Elf
                    </div>
                    <svg
                      className={`w-4 h-4 ${
                        isPagesElfOpen ? "svg-dropdown" : "svg-updown"
                      }`}
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  {isPagesElfOpen && (
                    <div className="flex flex-col justify-center mx-auto text-xs mt-3 sm:hidden">
                      <a
                        href="#"
                        className="cursor-pointer text-decoration-none"
                      >
                        Jakarta
                      </a>
                      <a
                        href="#"
                        className="cursor-pointer text-decoration-none"
                      >
                        Bogor
                      </a>
                      <a
                        href="#"
                        className="cursor-pointer text-decoration-none"
                      >
                        Depok
                      </a>
                      <a
                        href="#"
                        className="cursor-pointer text-decoration-none"
                      >
                        Tangerang
                      </a>
                      <a
                        href="#"
                        className="cursor-pointer text-decoration-none"
                      >
                        Bekasi
                      </a>
                    </div>
                  )}
                </div>
                <div className={`${isPagesHiaceOpen ? "" : "flex"}`}>
                  <div className="flex mx-auto justify-center">
                    <div
                      onClick={() => {
                        if (window.innerWidth <= 640) {
                          // handle click behavior for mobile screens
                          togglePagesMenu("hiace");
                        } else {
                          // handle click behavior for desktop screens
                          // toggle menu without updating state
                          window.location.href = "/rental-mobil/hiaace";
                        }
                      }}
                      className="nav__item menu__utama  cursor-pointer"
                    >
                      Rental Hiace
                    </div>
                    <svg
                      className={`w-4 h-4 ${
                        isPagesHiaceOpen ? "svg-dropdown" : "svg-updown"
                      }`}
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  {isPagesHiaceOpen && (
                    <div className="flex flex-col justify-center mx-auto text-xs mt-3 sm:hidden">
                      <p>Jakarta</p>
                      <p>Bogor</p>
                      <p>Depok</p>
                      <p>Tangerang</p>
                      <p>Bekasi</p>
                    </div>
                  )}
                </div>
                <NavLink
                  to="/blogs"
                  className={(navClass) =>
                    navClass.isActive
                      ? "nav__active nav__item  cursor-pointer"
                      : "nav__item  cursor-pointer"
                  }
                >
                  Blog
                </NavLink>
              </div>
            </div>
            <div className="nav__ri ml-10">
              <div className="search__box">
                <input type="text" placeholder="Search" />
                <span>
                  <i class="ri-search-line"></i>
                </span>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </header>
  );
};

export default Header;
