import React from "react";
import { Col, Container, Row } from "reactstrap";
import fortuner from "../../../assets/all-images/assetmobil/fortuner.png";

const WhyUs = () => {
  return (
    <section className="">
      <Container>
        <Row className="items-center">
          <Col lg="6" md="6">
            <div className="about__section-content">
              <h4 className="section__subtitle">Travel</h4>
              <h2 className="section__title">Kenapa Harus Kami?</h2>
              <p className="section__description">
                Bagi anda yang sedang mencari jasa angkutan travel untuk
                perusahaan jasa transportasi kami siap memenuhi untuk
                mengantarkan anda ke berbagai macam macam kota yang ada di
                Jabodetabek, Banten, Jawa Barat, Jawa Timur, Jawa Tengah,
                Jogjakarta dan Sumatera.
              </p>
            </div>
            <div className="about__section-item d-flex align-items-center">
              <p className="section__description d-flex align-items-center gap-2">
                <i class="ri-checkbox-circle-line"></i>Mobil bersih
              </p>
            </div>
            <div className="about__section-item d-flex align-items-center">
              <p className="section__description d-flex align-items-center gap-2">
                <i class="ri-checkbox-circle-line"></i>
                {"Penjemputan < 20 menit"}
              </p>
            </div>
            <div className="about__section-item d-flex align-items-center">
              <p className="section__description d-flex align-items-center gap-2">
                <i class="ri-checkbox-circle-line"></i>Kendaraan yang di
                lengkapi GPS Tracker
              </p>
            </div>
          </Col>
          <Col lg="6" md="6">
            <div className="about__img">
              <img src={fortuner} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WhyUs;
