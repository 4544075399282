import { Image } from "antd";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { Col, Container } from "reactstrap";
import NotFound from "../../../pages/NotFound";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import Helmet from "../../Helmet/Helmet";
import jakarta from "../../../assets/wisata/jakarta.jpeg";
import semarang from "../../../assets/wisata/semarang.jpeg";
import bali from "../../../assets/wisata/bali.jpeg";
import bandung from "../../../assets/wisata/bandung.jpeg";
import jogjakarta from "../../../assets/wisata/jogjakarta.jpeg";
import tangerang from "../../../assets/wisata/jakarta.jpeg";
import surabaya from "../../../assets/wisata/surabaya.jpeg";
import malang from "../../../assets/wisata/malang.jpeg";
import bogor from "../../../assets/wisata/bogor.jpeg";
import CardWisata from "./CardWisata";

const WisataSlug = () => {
  //TODO LAnjutin switch content
  function switchContent() {
    switch (slug) {
      case "jakarta":
        return (
          <div>
            <h5 className="text-subtitle my-4">
              Berikut Ini Rekomendasi 8 Destinasi Wisata Jakarta yang Wajib Anda
              Kunjungi!
            </h5>
            <p className="text-justify">
              Indonesia adalah negara yang kaya akan keanekaragaman sumber daya,
              keindahan dari pesona alam di Indonesia sudah tidak perlu
              diragukan lagi. Keelokan alam negeri ini tersohor sampai ke
              negara-negara tetangga. Salah satu dari sekian banyak yang menjadi
              incaran turis baik dalam negeri maupun luar negeri yaitu wisata
              Jakarta. Mungkin sebagian besar orang menganggap bahwa Jakarta
              adalah ibu kota metropolitan yang penuh sesak oleh aktivitas
              pemerintahan. Namun, ternyata banyak loh wisata indah dan menarik
              yang ada di kota ini.
            </p>
            <p className="text-justify">
              Semakin majunya zaman membuat variasi tempat wisata di berbagai
              daerah pun turut berkembang. Bukan lagi wisata alam yang
              mengandalkan ciptaan tuhan, melainkan kini sudah bisa kita jumpai
              banyak wisata buatan yang secara khusus dirancang oleh manusia
              dengan tujuan sebagai sarana hiburan dan tentu saja sebagai sarana
              untuk meningkatkan ekonomi daerah terkait. Bisa dibilang bahwa
              wisata buatan bahkan lebih terkenal dibandingkan dengan wisata
              alam, hal ini karena wisata buatan biasanya dirancang khusus untuk
              target pasar tertentu.
            </p>
            <p className="text-justify">
              Di dalam artikel ini, saya akan menyajikan ulasan mengenai
              beberapa rekomendasi tempat wisata Jakarta yang tentu wajib untuk
              Anda kunjungi. Mulai dari yang bertemakan wisata alam sampai
              dengan wisata buatan. Tentu saja daftar rekomendasi yang saya
              berikan kepada Anda adalah tempat-tempat yang instagrammable. Nah,
              apakah Anda penasaran ingin mengetahuinya? Ini dia, mari langsung
              saja kita simak ulasan di bawah ini!
            </p>
            <h5 className="text-subtitle my-4">
              Rekomendasi Destinasi Wisata di Jakarta
            </h5>
            <p className="text-justify">
              Daerah Khusus Ibu Kota Jakarta atau lebih dikenal dengan DKI
              Jakarta merupakan salah satu provinsi padat penduduk yang ada di
              Indonesia. Sebagai sebuah ibu kota negara tentunya Jakarta menjadi
              daerah yang sibuk, baik dari segi perputaran ekonomi,
              pemerintahan, maupun pariwisatanya. Orang dari luar daerah mungkin
              saja akan menempatkan Kota Jakarta di urutan yang kesekian dalam
              hal berwisata.
            </p>
            <p className="text-justify">
              Mereka merasa Jakarta tidak cocok dijadikan sebagai tempat
              refreshing karena dianggap terlalu padat. Namun, perlu untuk
              diketahui anggapan tersebut ternyata salah besar loh! Di kota ini
              banyak tersedia tempat-tempat menarik yang memanjakan mata para
              pelancong dari berbagai daerah. Jenisnya pun juga beragam,
              sehingga paket wisata murah Jakarta tidak perlu diragukan lagi
              kehebatannya. Yuk langsung saja kita tengok apa saja
              rekomendasinya!
            </p>
            <h6 className="font-semibold my-4 text-left">
              Taman Mini Indonesia Indah (TMII)
            </h6>
            <p className="text-justify">
              Di urutan pertama ada Taman Mini Indonesia Indah atau lebih
              dikenal dengan nama TMII. Tempat wisata yang satu ini mungkin
              sangat cocok dikunjungi jika hendak melakukan family trip dan
              wisata beredukasi. Hal ini dikarenakan TMII merupakan salah satu
              tempat yang menyajikan banyak pengetahuan khususnya berkaitan
              dengan budaya yang ada di Indonesia. Keragaman suku dan budaya
              Indonesia ditampilkan dalam bentuk miniatur, sehingga tidak perlu
              repot-repot keliling Indonesia, tinggal datangi saja TMII.
            </p>
            <p className="text-justify">
              Wisata Jakarta yang satu ini mungkin menjadi andalan bagi
              sekolah-sekolah yang sedang mengadakan perjalanan piknik. Sebagai
              wahana edukasi, tentu TMII tidak mematok tarif yang terlalu besar.
              Berdasarkan dari harga terbaru tahun 2022, cukup dengan menyiapkan
              dana Rp 25.000 saja Anda sudah bisa menikmati keindahan dari Taman
              Mini Indonesia Indah yang menakjubkan. Tarif ini tentu berlaku
              untuk setiap kepala yang memasuki wahana ya, bukan berkelompok.
            </p>
            <h6 className="font-semibold my-4 text-left">
              Taman Impian Jaya Ancol
            </h6>
            <p className="text-justify">
              Masih seputar rekomendasi tempat wisata yang cocok untuk family
              trip, yaitu Taman Impian Jaya Ancol. Terletak di Kawasan Jakarta
              Utara, Ancol kerapkali menjadi tujuan utama pelancong yang tengah
              berlibur bersama sanak keluarga. Beragam jenis wahana disediakan
              sehingga membuatnya sangat cocok dikunjungi jika sedang menikmati
              waktu bersama orang terkasih. Sama seperti TMII, Ancol juga
              menjadi kawasan terpadu yang menyajikan banyak wahana edukasi bagi
              para pengunjungnya.
            </p>
            <p className="text-justify">
              Berbicara mengenai sejarah dari salah satu wisata Jakarta ini,
              secara resmi Ancol diperkenalkan kepada publik di tahun 1966,
              dulunya Ancol bukanlah tempat wisata yang menyajikan banyak wahana
              bermain seperti saat ini, akan tetapi karena berjalannya waktu
              membuat tempat ini secara perlahan mengalami perubahan sehingga
              dapat menjadi destinasi wisata unggulaan di Jakarta. Selain
              menyajikan wahana edukasi, Ancol yang berdekatan dengan pantai pun
              menyediakan wahana air yang ramah anak, sangat menarik bukan?
            </p>
            <h6 className="font-semibold my-4 text-left">Wisata Kota Tua</h6>
            <p className="text-justify">
              Bagi Anda yang menggemari tempat wisata berbau sejarah mungkin
              Kota tua menjadi tujuan yang sangat tepat untuk dikunjungi.
              Tepatnya Wisata Kota Tua terletak di alun-alun Fathahillah.
              Sekilas bila dilihat kita bisa dengan mudah menebak bahwa bangunan
              yang ada di Kota Tua merupakan peninggalan Belanda, hal ini karena
              arsitekturnya yang sangat kental akan kebudayaan di masa Belanda
              dulu pernah menjajah Indonesia.
            </p>
            <p className="text-justify">
              Suasana vintage dan berbau dengan sejarah menjadi daya tarik
              tersendiri dari wisata yang satu ini. Sebagai destinasi wisata
              Jakarta yang banyak mencuri perhatian banyak orang, Kota Tua
              nyatanya memiliki kisah kisah mistis dibalik keindahannya loh!
              Banyak beredar cerita yang menyebutkan bahwa di beberapa spot
              bangunan kerap menjadi tempat munculnya hantu hantu noni Belanda
              yang meresahkan bagi sebagian besar orang. Eitsss jangan salah!
              Ternyata hal tersebut malah menjadi incaran banyak orang khususnya
              bagi Anda yang gemar petualangan spiritual.
            </p>
            <h6 className="font-semibold my-4 text-left">
              Taman Margasatwa Ragunan
            </h6>
            <p className="text-justify">
              Nah, rekomendasi yang selanjutnya adalah kebun binatang ragunan.
              Tempat ini sangat cocok dijadikan sebagai tujuan wisata bagi
              keluarga, anak-anak akan belajar mengenal ragam hewan di kebun
              binatang, hal ini karena ragunan berisi puluhan bahkan ratusan
              jenis hewan. Mulai dari hewan biasa sampai yang langka sekalipun
              ada di sini. Jika tertarik untuk mengunjungi taman margasatwa ini,
              Anda tidak perlu merogoh kocek terlalu dalam, siapkan saja dana
              sebesar Rp 4.000 saja untuk dewasa dan Rp 3.000 untuk anak-anak.
              Terjangkau bukan?
            </p>
            <p className="text-justify">
              Bisa dibilang ragunan menjadi wisata Jakarta yang cukup ikonik,
              selain karena banyak menjadi tujuan bagi wisatawan lokal, pada
              nyatanya Ragunan banyak digemari oleh pelancong dari luar daerah.
              Perlu untuk diketahui bagi Anda yang tertarik untuk mengunjungi
              tempat wisata ini, diakibatkan oleh kebijakan PPKM yang diterapkan
              pemerintah, jadwal kunjungan wisata ragunan mengalami perubahan,
              pengunjung diwajibkan untuk mendaftar melalui online sebelum
              mendapatkan tiket fisiknya.
            </p>
            <h6 className="font-semibold my-4 text-left">
              Dunia Fantasi (Dufan)
            </h6>
            <p className="text-justify">
              Tempat wisata yang satu ini letaknya berdekatan dengan Ancol,
              sehingga ketika ingin mengunjungi Ancol, Dufan mungkin bisa
              dijadikan sebagai opsi kedua. Nama Dufan mungkin sudah tidak asing
              lagi bagi sebagian besar penduduk Kota Jakarta. Tempat ini sudah
              ada sejak tahun 1986, dan kini menjadi destinasi wisata Jakarta
              dan termasuk dalam salah 1 paket wisata jakarta di situs ini yang
              melegenda dan masih banyak peminatnya. Banyak wahana yang
              ditawarkan oleh pengelola, dan tentu saja ramah dikantong. Selain
              itu, Dufan menjadi salah satu tempat yang cocok dijadikan family
              trip.
            </p>
            <p className="text-justify">
              Mungkin bagi pengunjung yang baru pertama kali mendatangi tempat
              ini akan sedikit kebingungan untuk menjangkaunya, nah maka
              ingatlah bahwa jika Anda menggunakan kendaraan motor masuklah
              melalui pintu sebelah timur, sedangkan jika menggunakan roda empat
              atau mobil maka masuk gerbang di sebelah barat. Untuk jam
              operasional wisata Jakarta Dufan sendiri saat ini dibuka setiap
              hari, dimulai dari pukul 6 pagi sampai dengan 6 sore. Sebelum
              mengunjungi Dufan, pastikan Anda sudah melakukan reservasi secara
              online terlebih dahulu.
            </p>
            <h6 className="font-semibold my-4 text-left">Kepulauan Seribu</h6>
            <p className="text-justify">
              Wisata Jakarta yang selanjutnya adalah kepulauan seribu.
              Keberadaan dari wisata yang satu ini setidaknya mematahkan
              anggapan bahwa Jakarta hanya berisi gedung-gedung bertingkat
              tinggi saja, pada nyatanya Jakarta memiliki gugusan pulau dan laut
              yang biru membentang dan sedap untuk dinikmati. Selain itu juga
              jauh dari hiruk pikuk pusat kota yang membuat pengunjung menjadi
              betah berlama-lama untuk menikmati keindahan surgawi dari pantai
              di Kepulauan Seribu.
            </p>
            <p className="text-justify">
              Biasanya wisatawan lebih menyukai kegiatan air seperti snorkeling
              dan diving jika sedang mengunjungi wisata Jakarta Pulau Seribu.
              Ketika menyelami laut yang jernih di Kepulauan Seribu mereka akan
              disuguhkan oleh pemandangan bawah laut yang diisi oleh beragam
              biota laut menakjubkan yang belum tentu bisa Anda temui di laut
              lainnya. Jajaran resort menjadi pendukung bagi Anda yang hendak
              melakukan liburan dengan menginap bersama orang-orang terkasih.
            </p>
            <h6 className="font-semibold my-4 text-left">
              Monumen Nasional (Monas)
            </h6>
            <p className="text-justify">
              Jika berbicara mengenai Wisata Jakarta, Monumen Nasional alias
              Monas pasti tidak akan pernah ketinggalan untuk dibahas. Bisa
              dibilang Monas merupakan ikonnya Kota Jakarta. Hampir sebagian
              besar wisatawan luar daerah menempatkan Monas sebagai destinasi
              wisata diurutan pertama. Meskipun tidak seindah dan seunik wisata
              yang lainnya, Monas terbukti memiliki daya tarik yang hebat di
              hati masyarakat. Nah, bila berkunjung ke Monas dan mencapai
              puncaknya Anda bisa melihat keindahan dan hiruk pikuk Kota Jakarta
              dari atas loh!
            </p>
            <p className="text-justify">
              Lampu-lampu yang berkerlipan akan terlihat menakjubkan jika
              dipandang di malam hari. Namun, yang menjadi banyak sorotan dari
              wisata Jakarta yang satu ini yaitu pucuk Monas yang terlapis emas
              seberat 35kg. Berkaitan dengan sejarah, Monas sendiri dibangun
              pada tahun 1959 di mana dengan tujuan untuk mengenang jasa rakyat
              pada kala itu yang turut serta membantu melawan penjajahan Belanda
              demi meraih kemerdekaan bangsa Indonesia. Jika ingin berkeliling
              di sekitar area Monas Anda bisa menyewa kendaraan yang telah
              disediakan.
            </p>
            <h6 className="font-semibold my-4 text-left">Hutan Bakau</h6>
            <p className="text-justify">
              Tibalah kita diulasan rekomendasi destinasi wisata Jakarta urutan
              yang terakhir, yaitu Hutan Bakau. Beberapa tempat wisata yang saya
              rekomendasikan di atas mungkin kurang cocok bagi Anda yang kurang
              menggemari tempat berhawa panas, sehingga mencari alternatif lain
              seperti tempat-tempat dengan pohon rindang mungkin adalah solusi
              yang tepat. Nah, hutan bakau bisa menjadi salah satunya. Tentu
              saja diisi oleh ribuan pohon bakau, suasana asri dan hijau akan
              memanjakan mata Anda. Pecinta wisata alam pasti sangat cocok
              dengan tempat yang satu ini.
            </p>
            <p className="text-justify">
              Wisata Jakarta mangrove atau hutan bakau ini tepatnya terletak di
              wilayah Jakarta Utara, untuk tarif masuk ke sini pun terbilang
              sangatlah terjangkau bahkan tidak dipungut biaya sepeserpun alias
              gratis, kecuali untuk uang membayar parkir. Nah, maka dari itu
              wisata mangrove sangat cocok bagi Anda yang mencari tempat
              berlibur terjangkau dan tentunya bisa merefresh diri.
            </p>
            <p className="text-justify">
              Itu dia beberapa rekomendasi destinasi wisata Jakarta, ternyata
              bukan hanya berisi aktivitas membosankan dan padat, pada nyatanya
              Jakarta menyimpan banyak wisata menarik di dalamnya loh! Semoga
              ulasan yang saya sampaikan kepada Anda dapat membantu dan menjadi
              referensi bagi yang hendak berlibur di wilayah Kota Jakarta.
              Selamat menikmati hari libur Anda. Jangan lupa untuk tekan like
              and share dan kunjungi situs https://alcrentcar.com/, sampai
              jumpa! Terima kasih!
            </p>
          </div>
        );
      case "semarang":
        return semarang;
      case "bali":
        return bali;
      case "bandung":
        return bandung;
      case "jogjakarta":
        return jogjakarta;
      case "surabaya":
        return surabaya;
      case "tangerang":
        return tangerang;
      case "malang":
        return (
          <div>
            <h1 className="text-subtitle my-4">
              Inilah 7 Pilihan Destinasi Wisata Malang untuk Menikmati Liburan
              di Akhir Pekan
            </h1>
            <p className="text-justify">
              Seiring dengan berjalannya waktu, Malang yang berada di provinsi
              Jawa Timur (Jatim) terus mengalami perkembangan destinasi wisata
              yang semakin bagus. Bagaimana tidak, Anda bisa mengamati sendiri
              dari begitu banyaknya paket wisata murah wisata Malang yang kini
              menarik dan sayang untuk dilewatkan begitu saja.
            </p>
            <p className="text-justify">
              Apalagi, Malang juga terkenal akan destinasi wisata alamnya
              seperti Gunung Bromo, Gunung Semeru, Pantai Goa Cina, Air Terjun
              Coban Rondo dan lain sebagainya yang mampu memanjakan mata setiap
              wisatawan yang berkunjung ke daerah tersebut
            </p>
            <p className="text-justify">
              Dari beberapa destinasi wisata yang disebutkan di atas, Anda
              tentunya ingin mengulik satu persatu destinasi wisata lain yang
              ada di kota Malang, bukan? Oleh karena itu, di artikel kali ini
              akan dibahas lebih lanjut mengenai berbagai macam destinasi wisata
              unik, menarik dan mampu memanjakan mata di Malang untuk menikmati
              waktu liburan di akhir pekan.
            </p>
            <h5 className="text-subtitle my-4">
              Mengapa Malang Cocok Dijadikan Sebagai Destinasi Wisata Liburan
              Akhir Pekan?
            </h5>
            <h6 className="font-semibold my-4 text-left">
              Dapat Menikmati Berbagai Macam Tempat Wisata Unik dan Menarik
            </h6>
            <p className="text-justify">
              Bagi Anda yang sering kali merasa bosan dengan tujuan wisata yang
              itu-itu saja dan tanpa adanya variasi, maka tidak ada salahnya
              mengunjungi aneka tempat wisata Malang yang menawarkan sejuta
              tempat menarik yang sayang untuk dilewatkan begitu saja.
            </p>
            <p className="text-justify">
              Dengan mengunjungi kota ini, Anda bisa menikmati berbagai jenis
              destinasi wisata unik dan menarik seperti pantai, gunung, air
              terjun, tempat ibadah bersejarah, taman, museum dan lain
              sebagainya. Bahkan, Anda nantinya juga bisa mengunjungi semua
              tempat wisata jika waktu dan situasinya memungkinkan.
            </p>
            <h6 className="font-semibold my-4 text-left">
              Hawa Sejuk dan Segar Begitu Nyaman untuk Dinikmati
            </h6>
            <p className="text-justify">
              Waktu liburan umumnya menjadi peluang kesempatan bagi seseorang
              untuk bisa menikmati udara segar dan bersih dari padatnya
              aktivitas pekerjaan sehari-hari. Bagaimana tidak, dengan
              mengunjungi tempat wisata tertentu, Anda nantinya bisa menikmati
              kenyamanan saat bangun di pagi hari dan disambut oleh udara yang
              begitu sejuk dan menyegarkan.
            </p>
            <p className="text-justify">
              Tentunya, kota Malang inilah yang bisa menjadi salah satu pilihan
              menarik yang menawarkan hal tersebut. Berada di suhu udara kisaran
              24-25 derajat Celcius, Anda akan dapat menghirup udara sejuk yang
              mungkin tidak Anda temukan sama sekali di ibu kota Jakarta.
            </p>
            <h6 className="font-semibold my-4 text-left">
              Dapat Menikmati Sajian Berbagai Wisata Kuliner
            </h6>
            <p className="text-justify">
              Jika bertanya mengenai apa kuliner yang khas dari kota Malang,
              tenu banyak orang akan menjawab dengan bakso, bukan? Namun
              meskipun bakso saat ini dikatakan sudah menjadi makanan sejuta
              umat, akan tetapi siapa sangka jika bakso khas Malang benar-benar
              memiliki cita rasa yang berbeda dari bakso lainnya.
            </p>
            <p className="text-justify">
              Oleh karena itu, selain mengunjungi aneka tempat wisata Malang
              dengan paket wisata malang, ada baiknya bagi Anda untuk menikmati
              berbagai sajian makanan kuliner khas makanan bakso. Tidak hanya
              itu, Anda juga bisa menikmati aneka sajian makanan menarik seperti
              rawon, cwie mie, angsle, orem-oremm, sego goreng mawut dan masih
              banyak lagi.
            </p>
            <h6 className="font-semibold my-4 text-left">
              Terkenal dengan Kota Penghasil Apel yang Khas
            </h6>
            <p className="text-justify">
              Dalam kehidupa sehari-hari, Anda tentu pernah mendengar jika
              Malang mendapat julukan sebagai Kota Apel, bukan? Ya, ini memang
              benar adanya lantaran Malang sendiri dikenal sebagai daerah
              penghasil buah apel yang memiliki cita rasa khas dari daerah
              lainnya.
            </p>
            <p className="text-justify">
              Jika Anda suka dengan buah yang satu ini, maka tidak ada salahnya
              untuk berkunjung ke Malang agar puas menikmati apel tersebut. Hal
              yang menariknya lagi, Anda juga bisa membeli apel dalam bentuk
              aslinya atau dalam bentuk olahan. Di antaranya yakni seperti
              keripik apel, sari buah apel, malang strudel dan aneka macam
              olahan apel lainnya.
            </p>
            <h5 className="text-subtitle my-4">
              Daftar Wisata Malang yang Bisa Anda Kunjungi Saat Liburan Akhir
              Pekan
            </h5>
            <h6 className="font-semibold my-4 text-left">
              Wisata Alam Gunung Bromo
            </h6>
            <p className="text-justify">
              Seolah tidak akan pernah luntur ditelah zaman, wisata Malang
              Gunung Bromo ini sepertinya masih menjadi favorit bagi banyak
              orang untuk menikmati pemandangan alam yang sejuk dan segar.
              Dengan menawarkan pemandangan gunung yang menarik banyak perhatian
              para pecinta alam karena keindahannya, banyak para wisatawan yang
              rela menempuh perjalanan lama untuk bisa sampai di lokasi Gunung
              Bromo yang satu ini.
            </p>
            <p className="text-justify">
              Disini, Anda bisa mengakses Gunung Bromo dengan menggunakan
              kendaraan roda dua dan empat. Akan tetapi, Anda sebaiknya perlu
              menyewa jeep untuk bisa sampai ke kaki gunung Bromo.
            </p>
            <p className="text-justify">
              Salah satu keuntungan yang bisa Anda peroleh ketika berkunjung di
              Gunung Bromo ini yakni dapat menikmati pemandangan sunrise yang
              mampu membuat mata terpesona akan keindahan alam dari Sang Maha
              Pencipta. Tidak cukup hanya itu saja, Anda juga bisa menimati
              wisata perjalanan ke Air Terjun Madakaripura yang letaknya tidak
              jauh dari Gunung Bromo.
            </p>
            <h6 className="font-semibold my-4 text-left">
              Wisata Malang Jatim Park 1
            </h6>
            <p className="text-justify">
              Kemudian, ada wisata Malang yang cukup terkenal bernama Jatim Park
              1. Dimana, tempat wisata yang satu ini dikatakan cukup populer di
              kalangan para wisatawan, baik lokal maupun luar negeri. Menawarkan
              berbagai destinasi permainan wahana seru, Jatim Park 1 ini bisa
              menjadi salah satu pilihan ketika Anda menikmati waktu liburan
              bersama dengan keluarga, teman maupun orang tersayang. Bahkan Anda
              yang baru pertama kali berkunjung ke Jatim Park 1 nantinya akan
              ketagihan dengan berbagai wahana permainan yang ditawarkan oleh
              pihak penyedia layanan.
            </p>
            <p className="text-justify">
              Bagaimana tidak, di destinasi wisata Malang yang satu ini para
              pengunjung bisa menikmati lebih dari 50 wahana untuk dikunjungi.
              Dimana, beberapa destinasi wisata tersebut antara lain seperti
              Galeri Etnik Nusantara, Flying Fox, Taman Sejarah, Flying Tornado,
              Volcano Coaster dan aneka macam destinasi wisata menarik lainnya.
              Bagi Anda yang tertarik untuk berkunjung ke tempat wisata ini,
              cukup menempuh perjalanan sekitar 30 menit sampai 1 jam dari pusat
              Kota Malang.
            </p>
            <h6 className="font-semibold my-4 text-left">
              Wisata Menarik Kampung Warna-Warni Jodipan
            </h6>
            <p className="text-justify">
              Jika Anda termasuk traveller yang suka dengan tempat-tempat unik
              dan estetik, tidak ada salahnya berkunjung ke Kampung Warna-Warni
              Jodipan. Dimana, di tempat ini sendiri umumnya menawarkan sensasi
              tempat pemadangan sebuah kampung yang mana dihiasi / dicat
              berbagai pernak-pernik warna-warni seperti pelangi. Tentu saja,
              ini akan menjadikan pemadangan lebih segar ketika melihat aneka
              macam warna di kampung tersebut, bukan?
            </p>
            <p className="text-justify">
              Bahkan, Anda juga bisa mengabadikan berbagai momen seru dan
              menarik di berbagai spot foto yang sayang untuk dilewatkan begitu
              saja. Untuk bisa mengunjungi tempat wisata Malang yang satu ini,
              Anda cukup datang ke lokasi Gang 1, Jodipan, Blimbing, Kota
              Malang, Jawa Timur. Adapun untuk harga tiket masuknya sendiri
              cukup terjangkau yakni sekitar Rp 3 ribu saja. Bagaimana, Anda
              tertarik untuk mengunjungi destinasi wisata yang satu ini?
            </p>
            <h6 className="font-semibold my-4 text-left">
              Wisata Seru di Kampung Biru Arema
            </h6>
            <p className="text-justify">
              Setelah Anda berkunjung ke wisata Malang di Kampung Warna-Warni
              Jodipan, maka Anda selanjutnya bisa beralih ke destinasi perumahan
              unik lainnya yakni bernama Kampung Biru Arema. Di tempat wisata
              yang satu ini, umumnya terdapat ratusan rumah warga yang mana
              sebagian besar rumahnya dicat dengan warna biru yang merupakan
              warna kebesaran Arema, klub sepak bolanya orang daerah Malang.
            </p>
            <p className="text-justify">
              Dengan berkunjung ke Kampung Biru Arema ini, Anda nantinya cukup
              membayar tiket masuk sebesar Rp 2.500 saja. Berlokasi di
              Kiduldalem RT 03 RW 05, kelurahan Kiduldalem, Kecamatan Klojen,
              Anda bisa menikmati pemandangan rumah-rumah unik dan bisa
              dijadikan untuk tempat berswafoto dengan latar biru maupun latar
              mural yang juga tidak kalah seru untuk dicoba.
            </p>
            <h6 className="font-semibold my-4 text-left">
              Taman Labirin Coban Rondo
            </h6>
            <p className="text-justify">
              Berada di lokasi Coban Rondo, Pandesari, Kecamatan Batu, destinasi
              wisata Malang bernama Taman Labirin Coban Rondo ini dikatakan
              menjadi salah satu tempat wisata yang cocok untuk dikunjungi
              bersama keluarga. Ya, labirin ini sendiri umumnya dibuat dari
              berbagai macam tanaman hijau setinggi dua meter. Adapun ketika
              masuk ke sini, Anda nantinya akan seolah merasa masuk ke dunia
              film fiksi seperti Alice in the Wonderland.
            </p>
            <p className="text-justify">
              Cukup menarik untuk dicoba sekarang juga, bukan? Tidak hanya
              menawarkan sensasi liburan yang hanya mencari jalan keluar dari
              labirin saja, Anda nantinya juga bisa menikmati berbagai aktivitas
              seru lainnya di Taman Labirin Coban Rondo. Dimana, disini Anda
              bisa bermain air di kawasan air terjun, outbond sampai dengan
              aktivitas berkemah yang sayang untuk Anda lewatkan begitu saja.
              Untuk harga tiket masuknya sendiri, Anda cukup membayar dengan
              harga Rp 10 ribu saja (belum termasuk aktivitas lain yang akan
              dimainkan).
            </p>
            <h6 className="font-semibold my-4 text-left">
              Wisata Alam Lumbung Stroberi Batu
            </h6>
            <p className="text-justify">
              Bagi Anda yang suka dengan buah berwarna berry berwarna merah,
              tidak ada salahnya berkunjung ke destinasi wisata Malang bernama
              Lumbung Stroberi Batu. Dimana, di sini dikenal sebagai tempat
              surganya bagi para pecinta stroberi. Dengan mendatangi lokasi
              tersebut, para pengunjung nantinya bakal dibawa untuk sekadar
              berkeliling ke kebun stroberi yang terbentang luas. Sementara itu,
              Anda juga akan melihat bagaimana bentuk bangunannya yang dipenuhi
              dengan lukisan buah berwarna merah yang satu ini.
            </p>
            <p className="text-justify">
              Jika melihat secara sekilas, lukisan tersebut nantinya akan
              menunjukkan kesan lukisan yang ceria. Adapun selain berkeliling
              dan diberikan banyak informasi soal stroberi, nantinya Anda juga
              bisa untuk memetik buah langsung dari pohonnya. Tentu saja, ini
              menjadi sebuah pengalaman tak terlupakan yang sayang untuk Anda
              lewatkan begitu saja, bukan? Bahkan, Anda yang datang ke Lumbung
              Stroberi Batu ini nantinya akan sangat puas menikmati buah
              stroberi yang langsung dipetik dari pohonnya.
            </p>
            <h6 className="font-semibold my-4 text-left">
              Destinasi Wisata The Onsen Hot Spring Resort
            </h6>
            <p className="text-justify">
              Ingin sekali bisa pergi berlibur ke Negeri Sakura, Jepang, ada
              baiknya bagi Anda untuk berkunjung ke destinasi wisata Malang
              favorit bernama The Onsen Hot Spring Resort Malang. Cukup dengan
              mendatangi lokasi di Jalan Arumdalu No.98, Songgokerto, Kecamatan.
              Batu, Kota Batu, Jawa Timur, Anda nantinya bisa menikmati sensasi
              liburan terbaik yang seolah-olah Anda sedang berada di negara
              Jepang. Tentu saja, ini menjadi tempat wisata yang sayang untuk
              Anda lewatkan begitu saja, bukan?
            </p>
            <p className="text-justify">
              Di The Onsen Hot Spring Resort ini, Anda nantinya juga bisa dengan
              mudah menjelajahi taman dengan pemandangan khas Negeri Sakura,
              Jepang dan ada juga Torii Gate. Tidak hanya itu saja, Anda
              nantinya juga bisa menyewa Yukata untuk bisa mengambil banyak
              sekali foto agar terkesan sedang berada di Jepang. Adapun yang
              paling utama, Anda tentunya sangat disarankan untuk bisa berendam
              di tempat pemandian air panas yang memang merupakan andalan dari
              tempat ini.
            </p>
            <p className="text-justify">
              Demikianlah beberapa destinasi wisata Malang yang bisa menjadi
              pilihan terbaik bagi Anda untuk menikmati liburan di akhir pekan
              nanti. Dengan begitu, maka liburan yang Anda kunjungi di kota ini
              akan semakin seru dan tentunya berkesan.
            </p>
            <p className="text-justify">
              Namun jika Anda memilih Alcrentcar.com, semua paket akan tersedia
              transparan dengan harga murah namun fasilitasnya unggulan. Seperti
              itulah tentang paket wisata malang dan bagaimana tips untuk
              memilihnya agar pengalaman berlibur Anda lebih terasa nyata.
            </p>
          </div>
        );
      case "bogor":
        return bogor;
      default:
        return jakarta;
    }
  }
  const dataCardWisata = [
    {
      title: "Semarang",
      image: semarang,
    },
    {
      title: "Bali",
      image: bali,
    },
    {
      title: "Bandung",
      image: bandung,
    },
    {
      title: "Jakarta",
      image: jakarta,
    },
    {
      title: "Surabaya",
      image: surabaya,
    },
    {
      title: "Jogjakarta",
      image: jogjakarta,
    },
    {
      title: "Tangerang",
      image: tangerang,
    },
    {
      title: "Malang",
      image: malang,
    },
    {
      title: "Bogor",
      image: bogor,
    },
  ];
  const contentStyle = {
    margin: 0,
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };
  const validSlugs = [
    "semarang",
    "bali",
    "bandung",
    "jakarta",
    "surabaya",
    "jogjakarta",
    "tangerang",
    "malang",
    "bogor",
  ];
  function pickedPicture() {
    switch (slug) {
      case "jakarta":
        return jakarta;
      case "semarang":
        return semarang;
      case "bali":
        return bali;
      case "bandung":
        return bandung;
      case "jogjakarta":
        return jogjakarta;
      case "surabaya":
        return surabaya;
      case "tangerang":
        return tangerang;
      case "malang":
        return malang;
      case "bogor":
        return bogor;
      default:
        return jakarta;
    }
  }

  const { slug } = useParams();
  if (!validSlugs.includes(slug)) {
    return <NotFound />;
  }
  console.log("ini slug", slug);
  return (
    <Fragment>
      <Header />
      <Helmet title={slug}>
        <section>
          <Container>
            <Col className="text-center">
              <h6 className="section__subtitle">Wisata</h6>
              <h2 className="section__title">Paket Wisata {slug}</h2>
              <Image src={pickedPicture()} style={contentStyle}></Image>
            </Col>
          </Container>
        </section>
        <section>
          <Container>
            <Col className="text-center">{switchContent()}</Col>
          </Container>
        </section>
        <section>
          <Container>
            <Col className="text-center">
              <h6 className="section__subtitle">Wisata</h6>
              <h2 className="section__title">
                Paket Wisata Murah Terlengkap Lainnya
              </h2>
              <div className="grid grid-cols-3 gap-4 mt-4">
                {dataCardWisata.map((data) => (
                  <CardWisata title={data.title} gambar={data.image} />
                ))}
              </div>
            </Col>
          </Container>
        </section>
      </Helmet>
      <Footer />
    </Fragment>
  );
};

export default WisataSlug;
