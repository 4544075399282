import React from "react";
import { Link } from "react-router-dom";

const CardKecamatan = (props) => {
  console.log(props.data);
  return (
    <div className="max-w-md w-1/4 rounded overflow-hidden shadow-lg bg-white border p-4">
      <ul>
        {props.data.map((data) => (
          <Link to="/" className="text-black no-underline ">
            <li className="flex items-center hover:text-subtitle mb-1">
              <span className="h-[8px] w-[8px] rounded-full bg-black inline-block mr-[10px]"></span>
              Rental Mobil {data}
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default CardKecamatan;
