import React, { Fragment, useState } from "react";
import HeroSlider from "../components/UI/HeroSlider";
import Helmet from "../components/Helmet/Helmet";
import FindCarForm from "../components/UI/FindCarForm";
import AboutSection from "../components/UI/About";
import ServicesList from "../components/UI/ServicesList";
import carData from "../assets/data/carData";
import CarItem from "../components/UI/CarItem";
import BecomeDriverSection from "../components/UI/BecomeDriverSection";
import Testimonial from "../components/UI/testimonial";

import BlogList from "../components/UI/BlogList";
import { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { useRef } from "react";
import { getBlogList } from "../utils/api";

const Home = () => {
  const [dataBlog, setBlog] = useState([]);
  useEffect(() => {
    getBlogList().then((result) => {
      setBlog(result.data);
      console.log("ini blog", result.data);
    });
  }, []);
  // console.log("ini adalah data blog", dataBlog[0]);

  const linkRef = useRef(null);
  // const goTo = (ref) => {
  //   console.log(ref);
  //   window.scrollTo({
  //     top: ref.offsetTop,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // };
  return (
    <Fragment>
      <Header />
      <Helmet title="Home">
        {/* ========= hero section -------------- */}
        <section className="p-0 hero__slider-section">
          <HeroSlider />

          {/* <div className="hero__form">
            <Container>
              <Row className="form__row">
                <Col lg="4" md="4" sm="12">
                  <div className="find__cars-left">
                    <h2>Find your best car here</h2>
                  </div>
                </Col>
                <Col lg="8" md="8" sm="12">
                  <FindCarForm />
                </Col>
              </Row>
            </Container>
          </div> */}
        </section>

        {/* =============== about section */}
        <AboutSection />

        {/* ========== service section ========== */}
        <section ref={linkRef}>
          <Container>
            <Row>
              <Col lg="12" className="mb-5 text-center">
                <h6 className="section__subtitle">Layanan</h6>
                <h2 className="section__title">Layanan Populer Kami</h2>
              </Col>
              <ServicesList />
            </Row>
          </Container>
        </section>

        {/* ================ Car offer section ============ */}
        <section>
          <Container>
            <Row>
              <Col lg="12" className="text-center mb-5">
                <h6 className="section__subtitle">Akomodasi</h6>
                <h2 className="section__">Penawaran Terbaik</h2>
              </Col>

              {carData.map((item) => (
                <CarItem item={item} key={item.id}></CarItem>
              ))}
            </Row>
          </Container>
        </section>

        {/* ===================== become a driver section ===================== */}
        <BecomeDriverSection />

        {/* ====================== Testimonial section ========================== */}
        <section>
          <Container>
            <Row>
              <Col lg="12" className="mb-4 text-center">
                <h6 className="section__subtitle">Pendapat Pelanggan</h6>
                <h2 className="section__title">Testimoni</h2>
              </Col>

              <Testimonial />
            </Row>
          </Container>
        </section>

        {/* ============= blog section ==================== */}
        <section>
          <Container>
            <Row>
              <Col lg="12" className="mb-5 text-center">
                <h6 className="section__subtitle">Jelajahi</h6>
                <h2 className="section__title">Blog Terakhir</h2>
              </Col>

              <BlogList data={dataBlog} />
            </Row>
          </Container>
        </section>
      </Helmet>
      <Footer />
    </Fragment>
  );
};

export default Home;
