import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
import aboutImg from "../../assets/all-images/assetmobil/reborn.png";

const About = () => {
  return (
    <section className="about__section">
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content">
              <h4 className="section__subtitle">Tentang Kami</h4>
              <h2 className="section__title">
                Selamat Datang di Aksara Lintas Cakrawala Rental Mobil
              </h2>
              <p className="section__description">
                Kami adalah rental mobil terpercaya dengan pengalaman lebih dari
                7 tahun. Kami beroperasi di daerah Jabodetabek dan sekitarnya
                dengan lebih dari 100 driver. Keamanan, kenyamanan, kebersihan,
                dan kepuasan pelanggan adalah hal yang utama bagi kami. Lebih
                dari 1000 existing client, merupakan bukti bahwa kami memiliki
                integritas.
              </p>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i>Mobil bersih
                </p>
              </div>
              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i>
                  {"Penjemputan < 20 menit"}
                </p>
              </div>
              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i>Kendaraan yang di
                  lengkapi GPS Tracker
                </p>
              </div>
            </div>
          </Col>
          <Col lg="6" md="6">
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
