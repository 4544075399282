// import all images from assets/images directory
import img001 from "../all-images/assetmobil/reborn.png";
import img002 from "../all-images/assetmobil/alphard.png";
import img003 from "../all-images/assetmobil/avanza.png";
import img004 from "../all-images/assetmobil/ertiga.png";
import img005 from "../all-images/assetmobil/expander.png";
import img006 from "../all-images/assetmobil/fortuner.png";
import img007 from "../all-images/assetmobil/hiace.png";
import img008 from "../all-images/assetmobil/mobilio.png";

const carData = [
  {
    id: 1,
    brand: "Toyota",
    rating: 112,
    carName: "Innova",
    imgUrl: img001,
    model: "4 Pintu 7 Kursi",
    price: "NEGO",
    speed: "MVP",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic",
    description:
      " Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam. Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam.",
  },

  {
    id: 2,
    brand: "Toyota",
    rating: 102,
    carName: "Alphard",
    imgUrl: img002,
    model: "4 Pintu 7 Kursi",
    price: 50,
    speed: "VAN",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic / Manual",
    description:
      " Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam. Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam.",
  },

  {
    id: 3,
    brand: "Toyota",
    rating: 132,
    carName: "Avanza",
    imgUrl: img003,
    model: "4 Pintu 7 Kursi",
    price: 65,
    speed: "MVP",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic / Manual",
    description:
      " Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam. Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam.",
  },

  {
    id: 4,
    brand: "Suzuki",
    rating: 102,
    carName: "Ertiga",
    imgUrl: img004,
    model: "4 Pintu 7 Kursi",
    price: 70,
    speed: "MVP",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic / Manual",
    description:
      " Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam. Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam.",
  },

  {
    id: 5,
    brand: "Mitsubishi",
    rating: 94,
    carName: "Xpander",
    imgUrl: img005,
    model: "4 Pintu 7 Kursi",
    price: 45,
    speed: "MVP",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic / Manual",
    description:
      " Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam. Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam.",
  },

  {
    id: 6,
    brand: "Toyota",
    rating: 119,
    carName: "Fortuner",
    imgUrl: img006,
    model: "4 Pintu 7 Kursi",
    price: 85,
    speed: "SUV",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic / Manual",
    description:
      " Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam. Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam.",
  },

  {
    id: 7,
    brand: "Toyota",
    rating: 82,
    carName: "HIACE",
    imgUrl: img007,
    model: "4 Pintu 7 Kursi",
    price: 50,
    speed: "Mini Bus",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic / Manual",
    description:
      " Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam. Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam.",
  },

  {
    id: 8,
    brand: "Honda",
    rating: 52,
    carName: "Mobilio",
    imgUrl: img008,
    model: "4 Pintu 7 Kursi",
    price: 50,
    speed: "MVP",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic / Manual",
    description:
      " Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam. Dolor labore lorem no accusam sit justo sadipscing labore invidunt voluptua, amet duo et gubergren vero gubergren dolor. At diam.",
  },
];

export default carData;
