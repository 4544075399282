import React, { Fragment } from "react";
import { Col, Container } from "reactstrap";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import Helmet from "../../Helmet/Helmet";
import "../../../index.css";
import AboutTravel from "./AboutTravel";
import cardPhoto from "../../../assets/card-photo.jpeg";
import WhyUs from "./WhyUs";
import { Link } from "react-router-dom";

const cardJabodetabek = [
  {
    title: "Jakarta - Bogor (PP)",
    harga: "Rp. 170rb perseat",
  },
  {
    title: "Jakarta - Depok (PP)",
    harga: "Rp. 150rb perseat",
  },
  {
    title: "Jakarta - Tangerang (PP)",
    harga: "Rp. 200rb perseat",
  },
  {
    title: "Jakarta - Bekasi (PP)",
    harga: "Rp. 200rb perseat",
  },
  {
    title: "Jakarta - Serang (PP)",
    harga: "Rp. 200rb perseat",
  },
  {
    title: "Jakarta - Banten (PP)",
    harga: "Rp. 200rb perseat",
  },
  {
    title: "Jakarta - Cilegon (PP)",
    harga: "Rp. 200rb perseat",
  },
  {
    title: "Jakarta - Merak (PP)",
    harga: "Rp. 200rb perseat",
  },
];
const cardJateng = [
  {
    title: "Jakarta - Purworejo (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Magelang (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Temanggung (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Kebumen (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Purwokerto (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Banjarnegara (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Purbalingga (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Wonosobo (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Cilacap (PP)",
    harga: "Rp. 400.000 perseat",
  },
  {
    title: "Jakarta - Sragen (PP)",
    harga: "Rp. 400.000 perseat",
  },
  {
    title: "Jakarta - Solo (PP)",
    harga: "Rp. 400.000 perseat",
  },
  {
    title: "Jakarta - Semarang (PP)",
    harga: "Rp. 400.000 perseat",
  },
  {
    title: "Jakarta - Kendal (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Pekalongan (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Pemalang (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Tegal (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Brebes (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Jepara (PP)",
    harga: "Rp. 500.000 perseat",
  },
  {
    title: "Jakarta - Demak (PP)",
    harga: "Rp. 500.000 perseat",
  },
  {
    title: "Jakarta - Kudus (PP)",
    harga: "Rp. 500.000 perseat",
  },
  {
    title: "Jakarta - Rembang (PP)",
    harga: "Rp. 500.000 perseat",
  },
];
const cardSumatera = [
  {
    title: "Jakarta - Lampung (PP)",
  },
  {
    title: "Jakarta - Palembang (PP)",
  },
  {
    title: "Jakarta - Padang (PP)",
  },
  {
    title: "Jakarta - Jambi (PP)",
  },
  {
    title: "Jakarta - Pekanbaru (PP)",
  },
  {
    title: "Jakarta - Medan (PP)",
  },
];

const cardJatim = [
  {
    title: "Jakarta - Madura (PP)",
    harga: "Rp. 800.000 perseat",
  },
  {
    title: "Jakarta - Surabaya (PP)",
    harga: "Rp. 700.000 perseat",
  },
  {
    title: "Jakarta - Lamongan (PP)",
    harga: "Rp. 700.000 perseat",
  },
  {
    title: "Jakarta - Mojokerto (PP)",
    harga: "Rp. 700.000 perseat",
  },
  {
    title: "Jakarta - Tulungagung (PP)",
    harga: "Rp. 700.000 perseat",
  },
  {
    title: "Jakarta - Kediri (PP)",
    harga: "Rp. 700.000 perseat",
  },
  {
    title: "Jakarta - Blitar (PP)",
    harga: "Rp. 700.000 perseat",
  },
  {
    title: "Jakarta - Malang (PP)",
    harga: "Rp. 700.000 perseat",
  },
  {
    title: "Jakarta - Banyuwangi (PP)",
    harga: "Rp. 850.000 perseat",
  },
  {
    title: "Jakarta - Denpasar (PP)",
    harga: "Rp. 1.000.000 perseat",
  },
];
const cardJabar = [
  {
    title: "Jakarta - Cirebon (PP)",
    harga: "Rp. 300.000 perseat",
  },
  {
    title: "Jakarta - Majalengka (PP)",
    harga: "Rp. 250.000 perseat",
  },
  {
    title: "Jakarta - Subang (PP)",
    harga: "Rp. 200.000 perseat",
  },
  {
    title: "Jakarta - Purwakarta (PP)",
    harga: "Rp. 200.000 perseat",
  },
  {
    title: "Jakarta - Indramayu (PP)",
    harga: "Rp. 250.000 perseat",
  },
  {
    title: "Jakarta - Tasikmalaya (PP)",
    harga: "Rp. 300.000 perseat",
  },
  {
    title: "Jakarta - Sukabumi (PP)",
    harga: "Rp. 250.000 perseat",
  },
  {
    title: "Jakarta - Bandung (PP)",
    harga: "Rp. 250.000 perseat",
  },
  {
    title: "Jakarta - Cianjur (PP)",
    harga: "Rp. 200.000 perseat",
  },
];

const cardJogja = [
  {
    title: "Jakarta - Klaten (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Bantul (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Kulon Progo (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Sleman (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Gunung Kidul (PP)",
    harga: "Rp. 400.000 perseat",
  },
];

const Travel = () => {
  return (
    <Fragment>
      <Header />
      <Helmet title="Travel">
        <AboutTravel />
        <section>
          <Container>
            <Col lg="12" className="mb-4 text-center">
              <h6 className="section__subtitle">Rental Mobil</h6>
              <h2 className="section__title">
                Aksara Lintas Cakrawala Rental Mobil
              </h2>
              <div className="my-5 text-description">
                <p>
                  Kami ada salah satu penyedia jasa travel yang mempunyai
                  beberapa kelebihan seperti armada yang baru bersih dan bersih
                  sehingga merasakan rasa bahagia dan nyaman dalam menemani
                  perjalanan anda..
                </p>
                <p>
                  Yang Lebih Murah Banyak, Yang Lebih Mahal juga Banyak. Akan
                  tetapi fasilitas dan pelayanan apakah dapat dipercaya /
                  diandalkan? Jangan sampai perjalanan dan liburan Anda terbuang
                  sia-sia begitu saja. Percayakan perjalanan dan liburan Anda
                  dengan Azkarentcar tour and Travel tidak perlu ribet urus ini
                  itu, Tinggal berangkat dan nikmati perjalanan liburan yang
                  hemat, nyaman dan menyenangkan.
                </p>
                <p>
                  Kami jemput anda di depan pintu rumah dan kami siap
                  mengantarkan andasampe pintu rumah tujuan anda.. dengan sytem
                  door to door ontime tepat waktu
                </p>
              </div>
              <button
                onClick={() =>
                  window.open(
                    "https://wa.me/6285156024563?text=Halo,%20Saya%20ingin%20menyewa%20mobil."
                  )
                }
                className="bg-title text-white p-3 rounded-md"
              >
                Hubungi Kami
              </button>
            </Col>
          </Container>
        </section>
        <section>
          {/* =========================== Jabodetabek ========================== */}
          <Container>
            <Col lg="12 mb-4 text-center">
              <h6 className="section__subtitle">Paket Travel</h6>
              <h2 className="section__title">
                Paket Travel Tujuan Dari Jakarta Ke Jabodetabek
              </h2>
              <div className="lg:grid lg:grid-cols-4 justify-center gap-4 mt-5 sm:grid sm:grid-cols-3">
                {cardJabodetabek.map((data) => (
                  <div className="card w-[200px] bg-teal-300 shadow-xl image-full mx-auto mb-3">
                    <figure className="object-cover h-full w-full">
                      <img src={cardPhoto} alt="Shoes" />
                    </figure>
                    <div className="card-body">
                      <h2 className="card-title text-amber">{data.title}</h2>
                      <p className="m-0 text-white">
                        Mobil terbaru, aman, wangi, dan bersih
                      </p>

                      <p className="m-0 text-white">{data.harga}</p>
                      <Link to="/travel/jakarta-jabodetabek">
                        <button className="bg-title text-white w-full mx-auto p-2 rounded-md text-sm">
                          Selengkapnya
                        </button>
                      </Link>
                      <div className="flex justify-between">
                        <div>
                          <button
                            onClick={() =>
                              window.open(
                                "https://wa.me/6285156024563?text=Halo,%20Saya%20ingin%20menyewa%20mobil."
                              )
                            }
                            className="bg-white text-title p-2 rounded-md text-sm"
                          >
                            WhatsApp
                          </button>
                        </div>
                        <div>
                          <a href="tel:085156024563">
                            <button className="bg-white text-title p-2 rounded-md text-sm">
                              Telp
                            </button>
                          </a>
                        </div>
                      </div>
                      {/* <div className="card-actions justify-end">
                        <button className="btn btn-primary">Buy Now</button>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Container>
          {/* =========================== Jawa Tengah ========================== */}

          <Container className="mt-10">
            <Col lg="12 mb-4 text-center">
              <h6 className="section__subtitle">Paket Travel</h6>
              <h2 className="section__title">
                Paket Travel Tujuan Dari Jakarta Ke Jawa Tengah
              </h2>
              <div className="lg:grid lg:grid-cols-4 justify-center gap-4 mt-5 sm:grid sm:grid-cols-3">
                {cardJateng.map((data) => (
                  <div className="card w-[200px] bg-teal-300 shadow-xl image-full mx-auto mb-3">
                    <figure className="object-cover h-full w-full">
                      <img src={cardPhoto} alt="Shoes" />
                    </figure>
                    <div className="card-body">
                      <h2 className="card-title text-amber">{data.title}</h2>
                      <p className="m-0 text-white">
                        Mobil terbaru, aman, wangi, dan bersih
                      </p>

                      <p className="m-0 text-white">{data.harga}</p>
                      <Link to="/travel/jakarta-jawa-tengah">
                        <button className="bg-title text-white w-full mx-auto p-2 rounded-md text-sm">
                          Selengkapnya
                        </button>
                      </Link>
                      <div className="flex justify-between">
                        <div>
                          <button
                            onClick={() =>
                              window.open(
                                "https://wa.me/6285156024563?text=Halo,%20Saya%20ingin%20menyewa%20mobil."
                              )
                            }
                            className="bg-white text-title p-2 rounded-md text-sm"
                          >
                            WhatsApp
                          </button>
                        </div>
                        <div>
                          <a href="tel:085156024563">
                            <button className="bg-white text-title p-2 rounded-md text-sm">
                              Telp
                            </button>
                          </a>
                        </div>
                      </div>
                      {/* <div className="card-actions justify-end">
                        <button className="btn btn-primary">Buy Now</button>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Container>
          {/* =========================== Sumatera ========================== */}
          <Container className="mt-10">
            <Col lg="12 mb-4 text-center">
              <h6 className="section__subtitle">Paket Travel</h6>
              <h2 className="section__title">
                Paket Travel Tujuan Dari Jakarta Ke Sumatera
              </h2>
              <div className="lg:grid lg:grid-cols-4 justify-center gap-4 mt-5 sm:grid sm:grid-cols-3">
                {cardSumatera.map((data) => (
                  <div className="card w-[200px] bg-teal-300 shadow-xl image-full mx-auto mb-3">
                    <figure className="object-cover h-full w-full">
                      <img src={cardPhoto} alt="Shoes" />
                    </figure>
                    <div className="card-body">
                      <h2 className="card-title text-amber">{data.title}</h2>
                      <p className="m-0 text-white">
                        Mobil terbaru, aman, wangi, dan bersih
                      </p>

                      <Link to="/travel/jakarta-sumatera">
                        <button className="bg-title text-white w-full mx-auto p-2 rounded-md text-sm">
                          Selengkapnya
                        </button>
                      </Link>
                      <div className="flex justify-between">
                        <div>
                          <button
                            onClick={() =>
                              window.open(
                                "https://wa.me/6285156024563?text=Halo,%20Saya%20ingin%20menyewa%20mobil."
                              )
                            }
                            className="bg-white text-title p-2 rounded-md text-sm"
                          >
                            WhatsApp
                          </button>
                        </div>
                        <div>
                          <a href="tel:085156024563">
                            <button className="bg-white text-title p-2 rounded-md text-sm">
                              Telp
                            </button>
                          </a>
                        </div>
                      </div>
                      {/* <div className="card-actions justify-end">
                        <button className="btn btn-primary">Buy Now</button>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Container>
          {/* =========================== Jawa Barat ========================== */}

          <Container className="mt-10">
            <Col lg="12 mb-4 text-center">
              <h6 className="section__subtitle">Paket Travel</h6>
              <h2 className="section__title">
                Paket Travel Tujuan Dari Jakarta Ke Jawa Barat
              </h2>
              <div className="lg:grid lg:grid-cols-4 justify-center gap-4 mt-5 sm:grid sm:grid-cols-3">
                {cardJabar.map((data) => (
                  <div className="card w-[200px] bg-teal-300 shadow-xl image-full mx-auto mb-3">
                    <figure className="object-cover h-full w-full">
                      <img src={cardPhoto} alt="Shoes" />
                    </figure>
                    <div className="card-body">
                      <h2 className="card-title text-amber">{data.title}</h2>
                      <p className="m-0 text-white">
                        Mobil terbaru, aman, wangi, dan bersih
                      </p>

                      <p className="m-0 text-white">{data.harga}</p>
                      <Link to="/travel/jakarta-jawa-barat">
                        <button className="bg-title text-white w-full mx-auto p-2 rounded-md text-sm">
                          Selengkapnya
                        </button>
                      </Link>
                      <div className="flex justify-between">
                        <div>
                          <button
                            onClick={() =>
                              window.open(
                                "https://wa.me/6285156024563?text=Halo,%20Saya%20ingin%20menyewa%20mobil."
                              )
                            }
                            className="bg-white text-title p-2 rounded-md text-sm"
                          >
                            WhatsApp
                          </button>
                        </div>
                        <div>
                          <a href="tel:085156024563">
                            <button className="bg-white text-title p-2 rounded-md text-sm">
                              Telp
                            </button>
                          </a>
                        </div>
                      </div>
                      {/* <div className="card-actions justify-end">
                        <button className="btn btn-primary">Buy Now</button>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Container>
          {/* =========================== Jawa Timur ========================== */}

          <Container className="mt-10">
            <Col lg="12 mb-4 text-center">
              <h6 className="section__subtitle">Paket Travel</h6>
              <h2 className="section__title">
                Paket Travel Tujuan Dari Jakarta Ke Jawa Timur
              </h2>
              <div className="lg:grid lg:grid-cols-4 justify-center gap-4 mt-5 sm:grid sm:grid-cols-3">
                {cardJatim.map((data) => (
                  <div className="card w-[200px] bg-teal-300 shadow-xl image-full mx-auto mb-3">
                    <figure className="object-cover h-full w-full">
                      <img src={cardPhoto} alt="Shoes" />
                    </figure>
                    <div className="card-body">
                      <h2 className="card-title text-amber">{data.title}</h2>
                      <p className="m-0 text-white">
                        Mobil terbaru, aman, wangi, dan bersih
                      </p>

                      <p className="m-0 text-white">{data.harga}</p>
                      <Link to="/travel/jakarta-jawa-timur">
                        <button className="bg-title text-white w-full mx-auto p-2 rounded-md text-sm">
                          Selengkapnya
                        </button>
                      </Link>
                      <div className="flex justify-between">
                        <div>
                          <button
                            onClick={() =>
                              window.open(
                                "https://wa.me/6285156024563?text=Halo,%20Saya%20ingin%20menyewa%20mobil."
                              )
                            }
                            className="bg-white text-title p-2 rounded-md text-sm"
                          >
                            WhatsApp
                          </button>
                        </div>
                        <div>
                          <a href="tel:085156024563">
                            <button className="bg-white text-title p-2 rounded-md text-sm">
                              Telp
                            </button>
                          </a>
                        </div>
                      </div>
                      {/* <div className="card-actions justify-end">
                        <button className="btn btn-primary">Buy Now</button>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Container>
          {/* =========================== Jogja ========================== */}

          <Container className="mt-10">
            <Col lg="12 mb-4 text-center">
              <h6 className="section__subtitle">Paket Travel</h6>
              <h2 className="section__title">
                Paket Travel Tujuan Dari Jakarta Ke Jogja
              </h2>
              <div className="lg:grid lg:grid-cols-4 justify-center gap-4 mt-5 sm:grid sm:grid-cols-3">
                {cardJogja.map((data) => (
                  <div className="card w-[200px] bg-teal-300 shadow-xl image-full mx-auto mb-3">
                    <figure className="object-cover h-full w-full">
                      <img src={cardPhoto} alt="Shoes" />
                    </figure>
                    <div className="card-body">
                      <h2 className="card-title text-amber">{data.title}</h2>
                      <p className="m-0 text-white">
                        Mobil terbaru, aman, wangi, dan bersih
                      </p>

                      <p className="m-0 text-white">{data.harga}</p>
                      <Link to="/travel/jakarta-jogjakarta">
                        <button className="bg-title text-white w-full mx-auto p-2 rounded-md text-sm">
                          Selengkapnya
                        </button>
                      </Link>
                      <div className="flex justify-between">
                        <div>
                          <button
                            onClick={() =>
                              window.open(
                                "https://wa.me/6285156024563?text=Halo,%20Saya%20ingin%20menyewa%20mobil."
                              )
                            }
                            className="bg-white text-title p-2 rounded-md text-sm"
                          >
                            WhatsApp
                          </button>
                        </div>
                        <div>
                          <a href="tel:085156024563">
                            <button className="bg-white text-title p-2 rounded-md text-sm">
                              Telp
                            </button>
                          </a>
                        </div>
                      </div>
                      {/* <div className="card-actions justify-end">
                        <button className="btn btn-primary">Buy Now</button>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Container>
        </section>
        <section>
          <WhyUs />
        </section>
      </Helmet>
      <Footer />
    </Fragment>
  );
};

export default Travel;
