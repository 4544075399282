

import React from 'react'

const BlogDetails = () => {
  return (
    <div>BlogDetails</div>
  )
}

export default BlogDetails