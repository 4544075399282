import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect, useState } from "react";
import { Form, Input, Button, Layout, message, Upload } from "antd";
import axios from "axios";
import "../../../styles/dashboard/post-blog.css";

import { UploadOutlined } from "@ant-design/icons";

const Blog = () => {
  //property untuk reactquill
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };
  //property untuk reactquill
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "code",
  ];

  //property upload
  const [convertedText, setConvertedText] = useState("Some default content");
  const [title, changeTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  //untuk trigger setstate
  const [dataDefault, setDefault] = useState(true);
  // const [form] = Form.useForm();
  // const title = Form.useWatch("title", form);

  //handle upload multipart
  const handleUpload = () => {
    const formData = new FormData();
    formData.append("judul", title);
    formData.append("konten", convertedText);
    fileList.forEach((file) => {
      formData.append("image", file);
    });
    setUploading(true);
    axios
      .post("http://localhost:8000/blogs", formData)
      .then((res) => {
        console.log(res);
      })
      .then(() => {
        setDefault(!dataDefault);

        message.success("Berhasil Posting Blog");
      })
      .catch((err) => {
        console.log("error", err.response.data);
        message.error("upload failed.");
      })
      .finally(() => {
        setUploading(false);
      });
  };

  //Property Componen Upload
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      console.log(file);
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  useEffect(() => {
    setFileList([]);
    // title = "";

    console.log("use effect terpanggil");
    setConvertedText("<p>Some default content</p>");
    changeTitle("Masukkan judul");
  }, [dataDefault]);
  //untuk form
  return (
    <Layout className="site-layout">
      <div>
        <h1 style={{ textAlign: "center" }}>Post a Blog</h1>
        <div style={{ display: "grid", justifyContent: "center" }}>
          <h5>Upload Gambar Blog</h5>

          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>

          <br />
          <h5>Judul</h5>
          <Form
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
          >
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input
                allowClear="true"
                defaultValue={title}
                autoCapitalize="true"
                value={title}
                onChange={(value) => {
                  changeTitle(value.target.value);
                }}
              />
            </Form.Item>
          </Form>
          <ReactQuill
            theme="snow"
            value={convertedText}
            onChange={setConvertedText}
            style={{
              minHeight: "300px",
              backgroundColor: "#fff",
            }}
            formats={formats}
            modules={modules}
          />
          <Button
            type="primary"
            onClick={handleUpload}
            disabled={
              fileList.length === 0 ||
              title === undefined ||
              convertedText === "<p>Some default content</p>"
            }
            loading={uploading}
            style={{
              marginTop: 16,
            }}
          >
            {uploading ? "Uploading" : "Start Upload"}
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default Blog;
