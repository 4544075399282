const serviceData = [
  {
    id: 1,
    title: "Antar Kota",
    icon: "ri-map-pin-2-line",
    desc: "Kami siap mengantar Anda ke seluruh Kota di Pulau Jawa",
  },

  {
    id: 2,
    title: "Tur Kota",
    icon: "ri-community-line",
    desc: "Ingin liburan keliling kota? Kami siap mengantar Anda ke berbagai landmark yang ada di Jakarta",
  },

  {
    id: 3,
    title: "Kendaraan Prima",
    icon: "ri-roadster-line",
    desc: "Keselamatan adalah salah satu prioritas kami. Kendaraan kami selalu dalam keadaan siap jalan, karena ditangani oleh montir-montir berpengalaman",
  },

  {
    id: 4,
    title: "Pemesanan Cepat dan Mudah",
    icon: "ri-timer-flash-line",
    desc: "Dengan jumlah driver yang banyak dan tersebar di berbagai pool, Kami siap menjemput Anda kurang dari 20 menit",
  },

  {
    id: 5,
    title: "Berbagai Lokasi Penjemputan",
    icon: "ri-map-pin-line",
    desc: "Cukup share location Anda, Driver kami siap menjemput Anda",
  },

  {
    id: 6,
    title: "Antar Jemput Bandara",
    icon: "ri-flight-takeoff-line",
    desc: "Akomodasi bandara mahal? Segera chat Kami, dengan harga yang terjangkau dan negotiable",
  },
];

export default serviceData;
