import { Collapse } from "antd";
import React, { Fragment } from "react";
import { useParams } from "react-router";
import { Col, Container, Row } from "reactstrap";
import NotFound from "../../../pages/NotFound";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import Helmet from "../../Helmet/Helmet";
import Testimonial from "../testimonial";
import AboutTravel from "./AboutTravel";
import CardCar from "./CardCar";
import WhyUs from "./WhyUs";
const { Panel } = Collapse;
const cardJabodetabek = [
  {
    title: "Jakarta - Bogor (PP)",
    harga: "Rp. 170rb perseat",
  },
  {
    title: "Jakarta - Depok (PP)",
    harga: "Rp. 150rb perseat",
  },
  {
    title: "Jakarta - Tangerang (PP)",
    harga: "Rp. 200rb perseat",
  },
  {
    title: "Jakarta - Bekasi (PP)",
    harga: "Rp. 200rb perseat",
  },
  {
    title: "Jakarta - Serang (PP)",
    harga: "Rp. 200rb perseat",
  },
  {
    title: "Jakarta - Banten (PP)",
    harga: "Rp. 200rb perseat",
  },
  {
    title: "Jakarta - Cilegon (PP)",
    harga: "Rp. 200rb perseat",
  },
  {
    title: "Jakarta - Merak (PP)",
    harga: "Rp. 200rb perseat",
  },
];
const cardJateng = [
  {
    title: "Jakarta - Purworejo (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Magelang (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Temanggung (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Kebumen (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Purwokerto (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Banjarnegara (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Purbalingga (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Wonosobo (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Cilacap (PP)",
    harga: "Rp. 400.000 perseat",
  },
  {
    title: "Jakarta - Sragen (PP)",
    harga: "Rp. 400.000 perseat",
  },
  {
    title: "Jakarta - Solo (PP)",
    harga: "Rp. 400.000 perseat",
  },
  {
    title: "Jakarta - Semarang (PP)",
    harga: "Rp. 400.000 perseat",
  },
  {
    title: "Jakarta - Kendal (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Pekalongan (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Pemalang (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Tegal (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Brebes (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Jepara (PP)",
    harga: "Rp. 500.000 perseat",
  },
  {
    title: "Jakarta - Demak (PP)",
    harga: "Rp. 500.000 perseat",
  },
  {
    title: "Jakarta - Kudus (PP)",
    harga: "Rp. 500.000 perseat",
  },
  {
    title: "Jakarta - Rembang (PP)",
    harga: "Rp. 500.000 perseat",
  },
];
const cardSumatera = [
  {
    title: "Jakarta - Lampung (PP)",
  },
  {
    title: "Jakarta - Palembang (PP)",
  },
  {
    title: "Jakarta - Padang (PP)",
  },
  {
    title: "Jakarta - Jambi (PP)",
  },
  {
    title: "Jakarta - Pekanbaru (PP)",
  },
  {
    title: "Jakarta - Medan (PP)",
  },
];

const cardJatim = [
  {
    title: "Jakarta - Madura (PP)",
    harga: "Rp. 800.000 perseat",
  },
  {
    title: "Jakarta - Surabaya (PP)",
    harga: "Rp. 700.000 perseat",
  },
  {
    title: "Jakarta - Lamongan (PP)",
    harga: "Rp. 700.000 perseat",
  },
  {
    title: "Jakarta - Mojokerto (PP)",
    harga: "Rp. 700.000 perseat",
  },
  {
    title: "Jakarta - Tulungagung (PP)",
    harga: "Rp. 700.000 perseat",
  },
  {
    title: "Jakarta - Kediri (PP)",
    harga: "Rp. 700.000 perseat",
  },
  {
    title: "Jakarta - Blitar (PP)",
    harga: "Rp. 700.000 perseat",
  },
  {
    title: "Jakarta - Malang (PP)",
    harga: "Rp. 700.000 perseat",
  },
  {
    title: "Jakarta - Banyuwangi (PP)",
    harga: "Rp. 850.000 perseat",
  },
  {
    title: "Jakarta - Denpasar (PP)",
    harga: "Rp. 1.000.000 perseat",
  },
];
const cardJabar = [
  {
    title: "Jakarta - Cirebon (PP)",
    harga: "Rp. 300.000 perseat",
  },
  {
    title: "Jakarta - Majalengka (PP)",
    harga: "Rp. 250.000 perseat",
  },
  {
    title: "Jakarta - Subang (PP)",
    harga: "Rp. 200.000 perseat",
  },
  {
    title: "Jakarta - Purwakarta (PP)",
    harga: "Rp. 200.000 perseat",
  },
  {
    title: "Jakarta - Indramayu (PP)",
    harga: "Rp. 250.000 perseat",
  },
  {
    title: "Jakarta - Tasikmalaya (PP)",
    harga: "Rp. 300.000 perseat",
  },
  {
    title: "Jakarta - Sukabumi (PP)",
    harga: "Rp. 250.000 perseat",
  },
  {
    title: "Jakarta - Bandung (PP)",
    harga: "Rp. 250.000 perseat",
  },
  {
    title: "Jakarta - Cianjur (PP)",
    harga: "Rp. 200.000 perseat",
  },
];

const cardJogja = [
  {
    title: "Jakarta - Klaten (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Bantul (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Kulon Progo (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Sleman (PP)",
    harga: "Rp. 350.000 perseat",
  },
  {
    title: "Jakarta - Gunung Kidul (PP)",
    harga: "Rp. 400.000 perseat",
  },
];
const validSlugs = [
  "jakarta-jawa-tengah",
  "jakarta-sumatera",
  "jakarta-jawa-timur",
  "jakarta-jawa-barat",
  "jakarta-jabodetabek",
  "jakarta-jogjakarta",
];

const TravelSlug = () => {
  function convertToTitleCase(str) {
    // split the string into an array of words
    let words = str.split("-");
    // iterate through each word in the array
    for (let i = 0; i < words.length; i++) {
      // capitalize the first letter of each word and make the rest of the letters lowercase
      words[i] =
        words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }
    // join the words back together with a space and return the result
    return words.join(" ");
  }
  function switchCard(kota) {
    switch (kota) {
      case "jakarta-jawa-tengah":
        return cardJateng.map((data) => <CardCar data={data} />);

      case "jakarta-jogjakarta":
        return cardJogja.map((data) => <CardCar data={data} />);

      case "jakarta-jawa-timur":
        return cardJatim.map((data) => <CardCar data={data} />);

      case "jakarta-jawa-barat":
        return cardJabar.map((data) => <CardCar data={data} />);

      case "jakarta-jabodetabek":
        return cardJabodetabek.map((data) => <CardCar data={data} />);

      default:
        break;
    }
  }
  function toCapital(string) {
    const capitalizedString = string.charAt(0).toUpperCase() + string.slice(1);
    return capitalizedString;
  }
  const { slug } = useParams();
  if (!validSlugs.includes(slug)) {
    return <NotFound />;
  }
  return (
    <Fragment>
      <Header />
      <Helmet title={slug}>
        <AboutTravel />
        <section>
          <Container>
            <Col className="text-center">
              <h6 className="section__subtitle">Travel</h6>
              <h2 className="section__title">
                Travel {convertToTitleCase(slug)} Terpercaya dan Terjangkau
              </h2>
              <div className="text-description  text-justify">
                <p>
                  Travel Jakarta Sumatera Terpercaya dan Terjangkau, Cocok untuk
                  Perjalanan Kalian dengan Menggunakan Jasa Azka Rent Car –
                  Jalan-jalan bersama keluarga memang sangat dibutuhkan, tidak
                  jarang ada banyak sekali orang yang mencari di mana travel
                  terbaik yang aman dan terpercaya untuk bisa membantu sampai ke
                  tujuan.
                </p>
                <p>
                  Dalam mencari Travel Jakarta Sumatera memang sangat dibutuhkan
                  yang sekiranya betul-betul terpercaya agar bisa membuat kita
                  sampai ke tujuan dengan selamat. Belum lagi, ada banyak sekali
                  hal-hal yang mestinya dipertimbangkan saat memilih travel. Ini
                  demi keamanan dan kenyamanan bersama agar bisa sampai tujuan
                  dengan selamat. Beberapa hal yang mestinya diperhatikan adalah
                  tingkat keamanannya dalam membawa penumpang, sehingga
                  penumpang merasa aman dan tidak takut ketika mobil sedang
                  melaju.
                </p>
                <p>
                  Rasa hati-hati dari supir juga sangat penting untuk menjamin
                  keselamatan bersama. Maka dari itu, sangat penting mencari
                  tempat berkualitas untuk bisa menunjang segala kegiatan baik
                  itu travelling ataupun acara lainnya yang berhubungan dengan
                  perjalanan cukup jauh tersebut dan jarak tempuh cukup lama.
                </p>
                <p>
                  Bepergian cukup jauh memang sangat penting untuk bisa mencari
                  travel jakarta Sumatera yang betul-betul berkualitas dan
                  terpercaya. Jika itu tidak dilakukan, tentu saja kita akan
                  merasa resah di sepanjang perjalanan karena takut dalam
                  memilihnya. Kenyamanan dalam berkendara menjadi salah satu
                  acuan yang sangat perlu diperhitungkan, maka dari itu sangat
                  penting untuk kita bisa mendapatkan pihak travel yang
                  betul-betul dapat memberikan rasa nyaman dan aman dalam
                  berkendara.
                </p>
                <p>
                  Pentingnya mengetahui trik dalam memilih travel juga sangat
                  penting untuk diketahui. Ini demi kenyamanan bersama yang
                  membuat kita tidak perlu lagi merasa bimbang dan khawatir
                  terhadap segala sesuatu yang akan terjadi. Jadi, kita
                  dipastikan aman dan akan baik-baik saja sepanjang perjalanan
                  karena sudah memilih travel yang betul-betul berkualitas dan
                  memberikan pelayanan terbaik tanpa perlu membuat kita merasa
                  takut dan khawatir ketika memesannya.
                </p>
              </div>
            </Col>
          </Container>
          <Container>
            <Col>
              <Collapse>
                <Panel header="Selengkapnya...">
                  <h2 className="section__title text-center my-3">
                    Trik Mendapatkan Travel {convertToTitleCase(slug)}{" "}
                    Terpercaya
                  </h2>
                  <h6 className="section__subtitle mb-3">
                    Pastikah Perusahaannya Jelas
                  </h6>
                  <div className="text-description">
                    <p>
                      Ini pastinya sangat penting untuk diketahui bersama,
                      mengingat travel jakarta Jogjakarta termasuk pilihan yang
                      cukup jauh untuk dilewati. Sehingga, sangat penting untuk
                      mengetahui sejak kapan perusahaan tersebut beroperasi.
                      Jika tidak diketahui secara jelas, maka khawatir akan
                      terjadi sesuatu yang bisa merugikan. Semakin kita
                      mengetahui secara detail di mana tempatnya, maka tentu itu
                      akan membuat kita sebagai penggunanya akan semakin merasa
                      tenang di dalam mobil.
                    </p>

                    <p>
                      Biasanya pula dengan mengetahui di mana perusahaan
                      tersebut berada, maka kita akan tahu markasnya benar-benar
                      meyakinkan atau tidak. Markas travel seharusnya memang
                      tersedia banyak mobil yang betul-betul beroperasi setiap
                      waktu. Itulah kenapa kita pun harus paham dan bisa tahu
                      betul bahwa travel yang kita pesan benar-benar sudah resmi
                      dan banyak digunakan oleh semua orang untuk menunjang
                      segala kegiatan mereka baik itu urusan keluarga ataupun
                      perkantoran.
                    </p>
                    <p>
                      Pentingnya memahami berbagai kondisi tersebut membuat kita
                      pun harus bisa cerdas dalam memilih suatu mobil yang akan
                      digunakan perjalanan jauh. Walaupun misalnya tidak bisa
                      memilih, maka paling tidak sudah bisa dipastikan bahwa
                      mobilnya benar-benar layak dan bagus untuk digunakan.
                      Apalagi, untuk seukuran travel jakarta Jogjakarta
                      seharusnya sudah benar-benar memadai segala kebutuhan dan
                      keperluan di dalamnya agar tidak membuat perjalanan
                      menjadi terhambat.
                    </p>
                    <p>
                      Tentunya akan banyak sekali dampak postif yang akan
                      dirasakan jika kita benar-benar mengecek secara rinci di
                      manakah letak dari markas travelnya. Biasanya juga
                      terdapat kartu nama yang akan membuat kita semakin yakin
                      bahwa tempat tersebut sudah berjalan sejak lama dan
                      menjadi kepercayaan semua orang. Dengan memerhatikan hal
                      tersebut, tentunya itu akan membuat kita pun semakin aman
                      dan tidak perlu khawatir lagi akan terjadi sesuatu yang
                      mengkhawatirkan
                    </p>
                    <p>
                      Kita harus tahu di mana tempatnya beroperasi untuk
                      kenyamanan dalam perjalanan. Semakin terpercaya tempat
                      tersebut dalam mengantarkan banyak penumpang maka itu akan
                      lebih baik pula untuk menunjang keamanan kita selama di
                      dalam mobil. Tidak perlu yang sampaikan cepat, cukup yang
                      hati-hati dan bisa mengendalikan laju mobil tanpa membuat
                      penumpang merasa ketakutan. Dengan travel jakarta
                      Jogjakarta yang memang bisa diandalkan tersebut, sudah
                      tentu harus bisa kita manfaatkan sebaik mungkin untuk
                      segera memesan
                    </p>
                    <p>
                      Jika kita tidak mengecek secara terang-terangan terlebih
                      dahulu, maka kita pun akan bingung ketika nanti terjadi
                      sesuatu. Pentingnya mengetahui tempatnya secara jelas itu
                      akan membuat kita tidak perlu lagi merasa bingung karena
                      sudah tentu bisa diandalkan oleh para pelanggannya. Ada
                      banyak sekali hal penting lainnya kenapa kita harus tahu
                      di mana markasnya, itu untuk menjadi jaminan bahwa memang
                      benar sudah beroperasi cukup lama
                    </p>
                  </div>
                  <h6 className="section__subtitle mb-3">
                    Cek Keamanannya Dari Travel {convertToTitleCase(slug)}
                  </h6>
                  <div className="text-description">
                    <p>
                      Berbagai hal bisa saja mengkhawatirkan jika tidak
                      diperhatikan dengan baik. Apalagi travel jakarta
                      Jogjakarta termasuk suatu perjalanan cukup jauh yang jika
                      tidak dicek keseluruhannya maka akan mengkhawatirkan semua
                      konsumennya. Bukan hal baru jika terdapat beberapa supir
                      yang sudah mencelakakan banyak orang, dengan kita mengecek
                      dan memantau secara jelas maka keamanan kita pun akan bisa
                      terjamin.
                    </p>
                    <p>
                      Setidaknya itu menjadi poin plus agar setiap konsumen
                      merasa baik-baik saja ketika ada di dalamnya. Kelayakan
                      dan kondisi dari mobilnya juga tidak boleh lepas dari
                      sorotan. Jika itu tidak kita perhatikan secara
                      terang-terangan maka tentu saja itu akan membahayakan
                      banyak orang.
                    </p>
                    <p>
                      Kondisi dari mobil akan menunjukkan mobilnya terawat atau
                      terbiasa diabaikan. Dengan kondisinya yang enak digunakan,
                      maka itu akan menjadi penunjang betahnya penumpang di
                      dalam mobil sehingga tidak perlu lagi merasa bimbang atau
                      bingung terhadap hal tersebut.
                    </p>
                    <p>
                      Kondisi sabuk pengaman juga jangan sampai diabaikan,
                      pastikan semuanya benar-benar layak dan kokoh untuk
                      digunakan. Tidak hanya itu, berbagai penunjang lain yang
                      berhubungan dengan keselamatan sangat penting untuk dicek
                      terlebih dahulu. Saat menggunakan travel jakarta
                      Jogjakarta di mana cukup memakan banyak waktu, akan lebih
                      baik sebelum memesannya maka perhatikan secara detail agar
                      tidak membuat kita merasa ketakutan ketika ada di dalam
                      mobilnya.
                    </p>
                  </div>
                </Panel>
              </Collapse>
            </Col>
          </Container>
        </section>
        <section>
          <Container>
            <Col className="text-center">
              <h6 className="section__subtitle">Paket Travel</h6>
              <h2 className="section__title">
                Paket Travel {convertToTitleCase(slug)}
              </h2>
              <div className="sm:grid sm:grid-cols-3 justify-center gap-3">
                {switchCard(slug)}
              </div>
            </Col>
          </Container>
        </section>
        <WhyUs />
        <section>
          <Container>
            <Row>
              <Col lg="12" className="mb-4 text-center">
                <h6 className="section__subtitle">Pendapat Pelanggan</h6>
                <h2 className="section__title">Testimoni</h2>
              </Col>
              <Testimonial />
            </Row>
          </Container>
        </section>
      </Helmet>
      <Footer />
    </Fragment>
  );
};

export default TravelSlug;
