export function dateToHour(isoString) {
  return new Date(isoString).toLocaleTimeString("id", {
    timeStyle: "short",
    hour12: false,
    timeZone: "UTC",
  });
}

export function isoToDate(isoString) {
  return new Date(isoString).toLocaleDateString(["jkt", "id"]);
}
