import React from "react";
import { Col, Container, Row } from "reactstrap";
import aboutImg from "../../../assets/all-images/assetmobil/reborn.png";

const AboutTravel = () => {
  return (
    <section className="">
      <Container>
        <Row className="items-center">
          <Col lg="6" md="6">
            <div className="about__section-content">
              <h4 className="section__subtitle">Travel</h4>
              <h2 className="section__title">Paket Mobil Travel Murah</h2>
              <p className="section__description">
                Bagi anda yang sedang mencari jasa angkutan travel untuk
                perusahaan jasa transportasi kami siap memenuhi untuk
                mengantarkan anda ke berbagai macam macam kota yang ada di
                Jabodetabek, Banten, Jawa Barat, Jawa Timur, Jawa Tengah,
                Jogjakarta dan Sumatera.
              </p>
            </div>
          </Col>
          <Col lg="6" md="6">
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutTravel;
