import React, { Fragment, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import carData from "../../../assets/data/carData";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import Helmet from "../../Helmet/Helmet";
import BlogList from "../BlogList";
import CarItem from "../CarItem";
import WhyUs from "../Travel/WhyUs";
import AboutRentalMobil from "./AboutRentalMobil";
import { getBlogList } from "../../../utils/api";
import { Link } from "react-router-dom";
import CardList from "./CardList";
import { Collapse } from "antd";

import CardKecamatan from "./CardKecamatan";
import kecamatan from "../../../assets/data/dataJakarta";
import CardWisata from "../Wisata/CardWisata";
import dataCardWisata from "../../../assets/data/dataCardWisata";
const { Panel } = Collapse;

const RentalMobil = () => {
  const [dataBlog, setBlog] = useState([]);
  useEffect(() => {
    getBlogList().then((result) => {
      setBlog(result.data);
      console.log("ini blog", result.data);
    });
  }, []);
  const jakbarLength = kecamatan.jakbar.length;
  const jakbarPartLength = Math.ceil(jakbarLength / 3);
  const jakbarPart1 = kecamatan.jakbar.slice(0, jakbarPartLength);
  const jakbarPart2 = kecamatan.jakbar.slice(
    jakbarPartLength,
    jakbarPartLength * 2
  );
  const jakbarPart3 = kecamatan.jakbar.slice(jakbarPartLength * 2);
  //   ============================
  const jakpusLength = kecamatan.jakpus.length;
  const jakpusPartLength = Math.ceil(jakpusLength / 3);
  const jakpusPart1 = kecamatan.jakpus.slice(0, jakpusPartLength);
  const jakpusPart2 = kecamatan.jakpus.slice(
    jakpusPartLength,
    jakpusPartLength * 2
  );
  const jakpusPart3 = kecamatan.jakpus.slice(jakpusPartLength * 2);
  //   =========================
  const jaktimLength = kecamatan.jaktim.length;
  const jaktimPartLength = Math.ceil(jaktimLength / 3);
  const jaktimPart1 = kecamatan.jaktim.slice(0, jaktimPartLength);
  const jaktimPart2 = kecamatan.jaktim.slice(
    jaktimPartLength,
    jaktimPartLength * 2
  );
  const jaktimPart3 = kecamatan.jaktim.slice(jaktimPartLength * 2);
  //   ==========================
  const jakselLength = kecamatan.jaksel.length;
  const jakselPartLength = Math.ceil(jakselLength / 3);
  const jakselPart1 = kecamatan.jaksel.slice(0, jakselPartLength);
  const jakselPart2 = kecamatan.jaksel.slice(
    jakselPartLength,
    jakselPartLength * 2
  );
  const jakselPart3 = kecamatan.jaksel.slice(jakselPartLength * 2);
  //   ========================
  const jakutLength = kecamatan.jakut.length;
  const jakutPartLength = Math.ceil(jakutLength / 3);
  const jakutPart1 = kecamatan.jakut.slice(0, jakutPartLength);
  const jakutPart2 = kecamatan.jakut.slice(
    jakutPartLength,
    jakutPartLength * 2
  );
  const jakutPart3 = kecamatan.jakut.slice(jakutPartLength * 2);

  return (
    <Fragment>
      <Header />
      <Helmet title="Rental Mobil">
        <AboutRentalMobil />
        <section>
          <Container>
            <Col className="text-center">
              <div className="mb-5">
                <h5 className="section__subtitle">
                  Pilihan Armada Mobil Rental Kami
                </h5>
                <h2 className="section__title">
                  Sewa Mobil Termurah di Indonesia
                </h2>
              </div>
              <div className="flex flex-wrap gap-3 justify-center">
                {carData.map((item) => (
                  <CarItem item={item} key={item.id}></CarItem>
                ))}
              </div>
            </Col>
          </Container>
        </section>
        <WhyUs />

        <section>
          <Container>
            <Col className="text-center">
              <h5 className="section__title">
                Sewa Rental Mobil Murah di Jakarta
              </h5>
              <p className="section__description text-justify">
                Azkarentcar Sebagai perusahaan yang menjual jasa, kami terus
                berinovasi meningkatkan layanan dan kualitas unit kami. Hal ini
                tentu untuk mengembangkan tujuan kami untuk menjadi sebuah
                perusahaan rental mobil Jakarta yang besar dan dari para Rental
                Mobil Jakarta pelanggan dan calon pelanggan kami sekarang dan
                akan datang. Selain perawatan berkala, selain perawatan kami
                juga selalu melakukan pengadaan mobil-mobil baru di setiap
                tahun.
              </p>
              <p className="section__description text-justify">
                Sehingga hampir bisa dipastikan mobil kami selalu terjaga akan
                persaingan dengan perusahan rental mobil Jakarta lainnya. jenis
                mobil yang kami siapkan sangat banyak dan beragam Toyota Avanza,
                Suzuki APV, Daihatsu Xenia dan luxio, Toyota Innova, Isuzu ELF
                (baik yang short atau long) KIA Pregio, Travello, Toyota Alphard
                /vellfire, Fortuner, Camry dan masih banyak lainnya. Anda pun
                dapat memesan sewa mobil mewah lain nya jika membutuhkan
              </p>
            </Col>
          </Container>
        </section>
        <section>
          <Container>
            <Row>
              <Col lg="12" className="mb-5 text-center">
                <h6 className="section__subtitle">Jelajahi</h6>
                <h2 className="section__title">Blog Terakhir</h2>
              </Col>

              <BlogList data={dataBlog} />
            </Row>
          </Container>
        </section>
        <section>
          <Container>
            <Col className="text-center">
              <h2 className="section__title mb-4">
                Alcrentcar: Rental Mobil Jakarta Berkualitas
              </h2>
              <p className="text-justify">
                Butuh layanan rental mobil jakarta selatan yang berkualitas
                bagus dan terjangkau harganya? Azkarentcar bisa menjadi solusi
                untuk Anda. Sebagai perusahaan yang bergerak di bidang rental
                kendaraan, kami telah berpengalaman cukup lama melayani
                kebutuhan rental mobil di Jakarta dan sewa mobil di jakarta.
                Berdiri sejak tahun 2010 di Jakarta Pusat dan saat ini telah
                memiliki banyak langganan baik dari luar maupun internal Kota
                Jakarta.
              </p>
              <p className="text-justify">
                Saat ini begitu mudah menemukan penyedia jasa rental mobil di
                Jakarta, tapi yang memberikan kepuasan bagi penumpang tentu
                harus benar-benar dicek. Lebih dari 90% dari konsumen kami
                mengaku puas menggunakan layanan jasa rental yang kami tawarkan.
                Kualitas armada yang bagus, banyaknya pilihan kendaraan dan
                pelayanan yang professional membuat banyak konsumen akhirnya
                menghubungi kami lagi saat datang untuk kedua kalinya di Jakarta
              </p>
              <h5 className="section__subtitle">
                Penyedia Rental Mobil Jakarta Terbaik di Alc Rent Car
              </h5>
              <p className="text-justify">
                Kami menyediakan berbagai pilihan pelayanan sewa mobil jakarta
                barat yang disesuaikan dengan kebutuhan para pengunjung di
                Jakarta.Jakarta sebagai pusat ibu kota bukan hanya dikunjungi
                orang untuk urusan pekerjaan, tetapi juga banyak dijadikan
                sebagai tempat rekreasi dan pertemuan organisasi atau komunitas.
                Karena lokasinya berada di tengah, secara ekonomis sangat
                berpotensi untuk dijadikan sebagai tempat pertemuan.
              </p>
              <p className="text-justify">
                Oleh karena itulah, kami pun berupaya memberikan layanan rental
                yang disesuaikan dengan kebutuhan konsumen. Berikut ini beberapa
                pilihan layanan rental mobil yang disediakan oleh Azka Rent Car,
                layanan rental mobil Jakarta terbaik saat ini:
              </p>
              <h6 className="section__subtitle text-left">
                Rental Mobil Paket Sopir
              </h6>
              <p className="text-justify">
                Mau keliling Jakarta tapi masih sulit mencari jalan-jalan yang
                bebas macet dan lokasi bangunan-bangunan tertentu? Jadikan
                Alcrentcar sebagai teman kerja anda. Kenapa harus kami? Bukankah
                banyak{" "}
                <Link
                  href="/home"
                  className="no-underline text-black font-bold"
                >
                  jasa sewa mobil di Jakarta Selatan?
                </Link>
              </p>
              <p className="text-justify">
                Ada banyak kelebihan jika anda menghubungi kami untuk kebutuhan
                perjalanan anda, salah satunya mendapatkan sopir yang sudah
                lebih dari 10 tahun melayani sewa mobil di Jakarta sehingga
                perjalanan anda pasti akan lancar.
              </p>
              <p className="text-justify">
                Paket rental plus sopir akan membantu Anda lebih hemat waktu,
                lebih hemat minyak dan pastinya sampai ke tempat tujuan dalam
                jangka waktu yang tidak terlalu lama. Terutama bagi Anda para
                pengunjung dari luar Kota Jakarta, bantuan seorang sopir
                berpengalaman tentu saja sangat dibutuhkan.
              </p>
              <h6 className="text-left section__subtitle">
                Tersedia Paket Wisata Jakarta
              </h6>
              <p className="text-justify">
                Mau keliling Jakarta tapi masih sulit mencari jalan-jalan yang
                bebas macet dan lokasi bangunan-bangunan tertentu? Jadikan
                Alcrentcar sebagai teman kerja anda. Kenapa harus kami? Bukankah
                banyak jasa sewa mobil di Jakarta Selatan?
              </p>
              <p className="text-justify">
                Ada banyak kelebihan jika anda menghubungi kami untuk kebutuhan
                perjalanan anda, salah satunya mendapatkan sopir yang sudah
                lebih dari 10 tahun melayani sewa mobil di Jakarta sehingga
                perjalanan anda pasti akan lancar.
              </p>
              <p className="text-justify">
                Paket rental plus sopir akan membantu Anda lebih hemat waktu,
                lebih hemat minyak dan pastinya sampai ke tempat tujuan dalam
                jangka waktu yang tidak terlalu lama. Terutama bagi Anda para
                pengunjung dari luar Kota Jakarta, bantuan seorang sopir
                berpengalaman tentu saja sangat dibutuhkan.
              </p>
            </Col>
          </Container>
        </section>
        <section>
          <div className="flex justify-center gap-3">
            <CardList title="Bus" />
            <CardList title="Elf" />
            <CardList title="Hiace" />
          </div>
        </section>
        <section>
          <Container>
            <Col className="text-center">
              <h5 className="section__subtitle">
                Rental Mobil Kami Tersedia Di:
              </h5>
              <Collapse className="mb-3">
                <Panel header="Jakarta Barat" className="text-left">
                  <div className="flex gap-3 justify-center">
                    <CardKecamatan data={jakbarPart1} />
                    <CardKecamatan data={jakbarPart2} />
                    <CardKecamatan data={jakbarPart3} />
                  </div>
                </Panel>
              </Collapse>
              <Collapse className="mb-3">
                <Panel header="Jakarta Pusat" className="text-left">
                  <div className="flex gap-3 justify-center">
                    <CardKecamatan data={jakpusPart1} />
                    <CardKecamatan data={jakpusPart2} />
                    <CardKecamatan data={jakpusPart3} />
                  </div>
                </Panel>
              </Collapse>
              <Collapse className="mb-3">
                <Panel header="Jakarta Timur" className="text-left">
                  <div className="flex gap-3 justify-center">
                    <CardKecamatan data={jaktimPart1} />
                    <CardKecamatan data={jaktimPart2} />
                    <CardKecamatan data={jaktimPart3} />
                  </div>
                </Panel>
              </Collapse>

              <Collapse className="mb-3">
                <Panel header="Jakarta Selatan" className="text-left">
                  <div className="flex gap-3 justify-center">
                    <CardKecamatan data={jakselPart1} />
                    <CardKecamatan data={jakselPart2} />
                    <CardKecamatan data={jakselPart3} />
                  </div>
                </Panel>
              </Collapse>

              <Collapse className="mb-3">
                <Panel header="Jakarta Utara" className="text-left">
                  <div className="flex gap-3 justify-center">
                    <CardKecamatan data={jakutPart1} />
                    <CardKecamatan data={jakutPart2} />
                    <CardKecamatan data={jakutPart3} />
                  </div>
                </Panel>
              </Collapse>
            </Col>
          </Container>
        </section>
        <section>
          <Container>
            <Col className="text-center">
              <h6 className="section__subtitle">Wisata</h6>
              <h2 className="section__title">
                Paket Wisata Murah Terlengkap Lainnya
              </h2>
              <div className="grid grid-cols-3 gap-4 mt-4">
                {dataCardWisata.map((data) => (
                  <CardWisata title={data.title} gambar={data.image} />
                ))}
              </div>
            </Col>
          </Container>
        </section>
        <section>
          <Container>
            <Col className="text-center">
              <h5 className="section__subtitle">
                Rental Mobil Kami Tersedia Di
              </h5>
              <div className="flex flex-wrap gap-3 place-content-center">
                <div className="mx-auto py-3 px-5 shadow-md rounded-sm text-subtitle border hover:-translate-y-2 transition duration-100 ease-in-out hover:shadow-2xl">
                  Jakarta
                </div>
                <div className="mx-auto py-3 px-5 shadow-md rounded-sm text-subtitle border hover:-translate-y-2 transition duration-100 ease-in-out hover:shadow-2xl">
                  Jakarta
                </div>
                <div className="mx-auto py-3 px-5 shadow-md rounded-sm text-subtitle border hover:-translate-y-2 transition duration-100 ease-in-out hover:shadow-2xl">
                  Jakarta
                </div>
                <div className="mx-auto py-3 px-5 shadow-md rounded-sm text-subtitle border hover:-translate-y-2 transition duration-100 ease-in-out hover:shadow-2xl">
                  Jakarta
                </div>
                <div className="mx-auto py-3 px-5 shadow-md rounded-sm text-subtitle border hover:-translate-y-2 transition duration-100 ease-in-out hover:shadow-2xl">
                  Jakarta
                </div>
                <div className="mx-auto py-3 px-5 shadow-md rounded-sm text-subtitle border hover:-translate-y-2 transition duration-100 ease-in-out hover:shadow-2xl">
                  Jakarta
                </div>
                <div className="mx-auto py-3 px-5 shadow-md rounded-sm text-subtitle border hover:-translate-y-2 transition duration-100 ease-in-out hover:shadow-2xl">
                  Jakarta
                </div>
                <div className="mx-auto py-3 px-5 shadow-md rounded-sm text-subtitle border hover:-translate-y-2 transition duration-100 ease-in-out hover:shadow-2xl">
                  Jakarta
                </div>
                <div className="mx-auto py-3 px-5 shadow-md rounded-sm text-subtitle border hover:-translate-y-2 transition duration-100 ease-in-out hover:shadow-2xl">
                  Jakarta
                </div>
              </div>
            </Col>
          </Container>
        </section>
      </Helmet>
      <Footer />
    </Fragment>
  );
};

export default RentalMobil;
