import React, { Fragment } from "react";
import { Col, Container } from "reactstrap";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Helmet from "../components/Helmet/Helmet";
import Lottie from "react-lottie";
import animationData from "../assets/404.json";

const NotFound = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Fragment>
      <Header />
      <Helmet title="404 not found">
        <section>
          <Container>
            <Col>
              <Lottie options={defaultOptions} height={400} width={400} />
            </Col>
          </Container>
        </section>
      </Helmet>
      <Footer />
    </Fragment>
  );
};

export default NotFound;
