import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "../../styles/testimonial.css";
import { getTestimoni } from "../../utils/api";

const capitalizeFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 3000,
  swipeToSlide: true,
  autoplaySpeed: 2000,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        // infinite:true,
        // dots:true
      },
    },
  ],
};

const Testimonial = () => {
  const [testimoni, setTestimoni] = useState([]);
  useEffect(() => {
    getTestimoni().then((result) => {
      console.log("ini testimoni", result.message);
      setTestimoni(result.message);
    });
  }, []);
  console.log("ini testimoniiiiii,", testimoni);
  return (
    <Slider {...settings}>
      {testimoni.map((item) => (
        <div className="testimonial py-4 py-3">
          <p className="section__description">
            {capitalizeFirst(item.komentar)}
          </p>

          <div className="mt-3 d-flex align-items-center gap-4">
            <img src={item.image} alt="" className="w-25 h-25 rounded-2" />
            <div>
              <h6 className="mb-0 mt-3">
                {capitalizeFirst(item.customerName)}
              </h6>
              <p className="section__description">Customer</p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default Testimonial;
