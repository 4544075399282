import { UserOutlined, DesktopOutlined, TeamOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { useState } from "react";
import Blog from "./dashboard/Blog";
import ListBlog from "./dashboard/ListBlog";
import PostTestimoni from "./dashboard/PostTestimoni";
const { Sider } = Layout;

function Password(props) {
  return (
    <Layout className="site-layout">
      <div
        style={{
          padding: 24,
          minHeight: 360,
        }}
      >
        password
      </div>
    </Layout>
  );
}
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem("Blog", "blog", <DesktopOutlined />, [
    getItem("Post Blog", "postBlog"),
    getItem("List Blog", "listBlog"),
  ]),
  getItem("Testimoni", "testimoni", <TeamOutlined />, [
    getItem("Post Testimoni", "postTestimoni"),
  ]),
];

function renderSwitch(param) {
  switch (param) {
    case "pw":
      return <Password />;
    case "listBlog":
      return <ListBlog />;
    case "postTestimoni":
      return <PostTestimoni />;
    default:
      return <Blog />;
  }
}
const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [menu, setMenu] = useState("blog");

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
      className="p-0"
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Menu
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          onClick={(data) => {
            setMenu(data.key);
            console.log(menu);
          }}
          items={items}
        />
      </Sider>
      {renderSwitch(menu)}
    </Layout>
  );
};
export default Dashboard;
