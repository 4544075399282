import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { Col } from "reactstrap";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import BlogList from "../components/UI/BlogList";
import Starter from "../components/UI/Starter";
import { getBlogList } from "../utils/api";
import About from "./About";

const Blog = () => {
  const [blog, setBlog] = useState([]);
  useEffect(() => {
    getBlogList().then((result) => {
      setBlog(result.data);
      console.log("ini blog", result.data);
    });
  }, []);
  return (
    <Fragment>
      <Header />
      <Starter />
      <Col lg="12" className="mb-5 text-center">
        <h6 className="section__subtitle">Jelajahi</h6>
        <h2 className="section__title">Blog Terakhir</h2>
      </Col>

      <Col lg="12" className="">
        <BlogList data={blog} />
      </Col>
      <Footer />
    </Fragment>
  );
};

export default Blog;
