import React from "react";
import { Container } from "reactstrap";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Upload,
  Col,
  Layout,
} from "antd";
import "../../../styles/dashboard/post-testimoni.css";
import { useState } from "react";
const { TextArea } = Input;

const PostTestimoni = () => {
  return (
    <Layout className="site-layout">
      <div>
        <h1 style={{ textAlign: "center" }}>Post a Testimoni</h1>
        <div style={{ justifyContent: "center" }} className="w-75">
          <Form
            size="large"
            labelCol={{
              span: 7,
            }}
            wrapperCol={{
              span: 20,
            }}
            layout="horizontal"
          >
            <Form.Item label="Nama">
              <Input />
            </Form.Item>
            <Form.Item label="Role">
              <Select>
                <Select.Option value="customer">Customer</Select.Option>
                <Select.Option value="driver">Driver</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Komentar">
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item label="Upload Foto" valuePropName="fileList">
              <Upload action="/upload.do" listType="picture-card">
                <div>
                  <PlusOutlined />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    Upload
                  </div>
                </div>
              </Upload>
            </Form.Item>
            <Form.Item label="Button">
              <Button>Button</Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default PostTestimoni;
